import {
    taskHistorySortByOptions,
    tasksSortByOptions,
} from '../../constants/tasksSortAndFilters';
import { Attachment, ConversationLine, CustomField, PageData } from '../common/types';
import { Company } from '../companies/types';
import { Contact, Customer } from '../customers/types';
import { Invoice } from '../invoices/types';
import { Ticket } from '../tickets/types';
import {
    ResponseModalObject,
    DynamicObject,
    AddCommentCommonFilterPayload,
} from '../../utils/commonInterfaces';
import { commonInvoiceListInvoicesSortOptions } from '../../constants/invoicesSortAndFilters';
import {
    GetPaymentPlanDataScheduleRequestPayload,
    PaymentPlan,
    PaymentPlanSchedule,
} from '../paymentPlans/types';
import { Payment } from '../payments/types';
import { RemittanceAdvice } from '../remittanceAdvices/types';
import { User } from '../users/types';

export enum TasksActionTypes {
    GET_TASKS_REQUEST = '@@tasks/GET_TASKS_REQUEST',
    GET_TASKS_FOR_ORGANISATION_REQUEST = '@@tasks/GET_TASKS_FOR_ORGANISATION_REQUEST',
    GET_TASKS_SUCCESS = '@@tasks/GET_TASKS_SUCCESS',
    GET_TASKS_ERROR = '@@tasks/GET_TASKS_ERROR',
    UPDATE_TASKS_FILTERS = '@@tasks/UPDATE_TASKS_FILTERS',
    UPDATE_TASKS_SORT_BY_AND_STATUS = '@@tasks/UPDATE_TASKS_SORT_BY_AND_STATUS',
    UPDATE_TASKS_TABLE_FILTER_STATUS = '@@tasks/UPDATE_TASKS_TABLE_FILTER_STATUS',
    CLEAR_TASKS_STATE_ALL_TABLE_FILTERS = '@@tasks/CLEAR_TASKS_STATE_ALL_TABLE_FILTERS',
    CLEAR_TASKS_STATE_DATA = '@@tasks/CLEAR_TASKS_STATE_DATA',
    CHECK_TASKS_READY_STATE = '@@tasks/CHECK_TASKS_READY_STATE',
    NOTIFY_CUSTOMERS_REQUEST = '@@tasks/NOTIFY_CUSTOMERS_REQUEST',
    NOTIFY_CUSTOMERS_RESPONSE = '@@tasks/NOTIFY_CUSTOMERS_RESPONSE',

    GET_TASKS_ACTION_FILTER_OPTIONS_REQUEST = '@@tasks/GET_TASKS_ACTION_FILTER_OPTIONS_REQUEST',
    GET_TASKS_ACTION_FILTER_OPTIONS_RESPONSE = '@@tasks/GET_TASKS_ACTION_FILTER_OPTIONS_RESPONSE',

    SET_TASK_SELECTED_ID_REQUEST = '@@tasks/SET_TASK_SELECTED_ID_REQUEST',
    SET_TASK_SELECTED_ID_SUCCESS = '@@tasks/SET_TASK_SELECTED_ID_SUCCESS',
    GET_TASK_DATA_REQUEST = '@@tasks/GET_TASK_DATA_REQUEST',
    GET_TASK_DATA_FOR_ORGANISATION_REQUEST = '@@tasks/GET_TASK_DATA_FOR_ORGANISATION_REQUEST',
    GET_TASK_DATA_SUCCESS = '@@tasks/GET_TASK_DATA_SUCCESS',
    GET_TASK_DATA_ERROR = '@@tasks/GET_TASK_DATA_ERROR',
    CLEAR_TASK_DATA_SUCCESS = '@@tasks/CLEAR_TASK_DATA_SUCCESS',

    TASKS_GET_TABLE_COMPACT_VIEW_REQUEST = '@@tasks/TASKS_GET_TABLE_COMPACT_VIEW_REQUEST',
    TASKS_GET_TABLE_COMPACT_VIEW_SUCCESS = '@@tasks/TASKS_GET_TABLE_COMPACT_VIEW_SUCCESS',
    TASKS_SET_TABLE_COMPACT_VIEW_REQUEST = '@@tasks/TASKS_SET_TABLE_COMPACT_VIEW_REQUEST',
    TASKS_SET_TABLE_COMPACT_VIEW_SUCCESS = '@@tasks/TASKS_SET_TABLE_COMPACT_VIEW_SUCCESS',
    SET_TASK_EXPAND_STATUS = '@@tasks/SET_TASK_EXPAND_STATUS',
    TASKS_PROCESS_COMPACT_VIEW_REDUX_SETTING = '@@tasks/TASKS_PROCESS_COMPACT_VIEW_REDUX_SETTING',

    // Invoices
    GET_TASK_TICKET_INVOICES_REQUEST = '@@tasks/GET_TASK_TICKET_INVOICES_REQUEST',
    GET_TASK_TICKET_INVOICES_SUCCESS = '@@tasks/GET_TASK_TICKET_INVOICES_SUCCESS',
    GET_TASK_TICKET_INVOICES_ERROR = '@@tasks/GET_TASK_TICKET_INVOICES_ERROR',
    UPDATE_TASK_TICKET_INVOICES_FILTERS = '@@tasks/UPDATE_TASK_TICKET_INVOICES_FILTERS',
    UPDATE_TASK_TICKET_INVOICES_TABLE_FILTERS = '@@tasks/UPDATE_TASK_TICKET_INVOICES_TABLE_FILTERS',
    UPDATE_TASK_TICKET_INVOICES_SORT_BY_AND_STATE = '@@tasks/UPDATE_TASK_TICKET_INVOICES_SORT_BY_AND_STATE',

    GET_TASK_PAYMENT_PLAN_SCHEDULE_REQUEST = '@@tasks/GET_TASK_PAYMENT_PLAN_SCHEDULE_REQUEST',
    GET_TASK_PAYMENT_PLAN_SCHEDULE_SUCCESS = '@@tasks/GET_TASK_PAYMENT_PLAN_SCHEDULE_SUCCESS',
    GET_TASK_PAYMENT_PLAN_SCHEDULE_ERROR = '@@tasks/GET_TASK_PAYMENT_PLAN_SCHEDULE_ERROR',
    UPDATE_TASK_PAYMENT_PLAN_SCHEDULE_FILTERS = '@@tasks/UPDATE_TASK_PAYMENT_PLAN_SCHEDULE_FILTERS',
    UPDATE_TASK_PAYMENT_PLAN_SCHEDULE_SORT_BY_AND_STATE = '@@tasks/UPDATE_TASK_PAYMENT_PLAN_SCHEDULE_SORT_BY_AND_STATE',

    /**
     * Task History section
     */
    GET_TASK_HISTORY_REQUEST = '@@tasks/GET_TASK_HISTORY_REQUEST',
    GET_TASK_HISTORY_SUCCESS = '@@tasks/GET_TASK_HISTORY_SUCCESS',
    GET_TASK_HISTORY_ERROR = '@@tasks/GET_TASK_HISTORY_ERROR',
    UPDATE_TASK_HISTORY_FILTERS = '@@tasks/UPDATE_TASK_HISTORY_FILTERS',
    UPDATE_TASK_HISTORY_SORT_BY = '@@tasks/UPDATE_TASK_HISTORY_SORT_BY',
    UPDATE_TASK_HISTORY_TABLE_FILTER_STATUS = '@@tasks/UPDATE_TASK_HISTORY_TABLE_FILTER_STATUS',
    CLEAR_TASK_HISTORY_STATE_ALL_TABLE_FILTERS = '@@tasks/CLEAR_TASK_HISTORY_STATE_ALL_TABLE_FILTERS',
    CLEAR_TASK_HISTORY_STATE_DATA = '@@tasks/CLEAR_TASK_HISTORY_STATE_DATA',

    SET_TASK_HISTORY_SELECTED_ID_REQUEST = '@@tasks/SET_TASK_HISTORY_SELECTED_ID_REQUEST',
    SET_TASK_HISTORY_SELECTED_ID_SUCCESS = '@@tasks/SET_TASK_HISTORY_SELECTED_ID_SUCCESS',
    GET_TASK_HISTORY_DATA_REQUEST = '@@tasks/GET_TASK_HISTORY_DATA_REQUEST',
    GET_TASK_HISTORY_DATA_SUCCESS = '@@tasks/GET_TASK_HISTORY_DATA_SUCCESS',
    GET_TASK_HISTORY_DATA_ERROR = '@@tasks/GET_TASK_HISTORY_DATA_ERROR',
    CLEAR_TASK_HISTORY_DATA_SUCCESS = '@@tasks/CLEAR_TASK_HISTORY_DATA_SUCCESS',

    // Notifications list
    GET_TASK_HISTORY_NOTIFICATIONS_REQUEST = '@@tasks/GET_TASK_HISTORY_NOTIFICATIONS_REQUEST',
    GET_TASK_HISTORY_NOTIFICATIONS_SUCCESS = '@@tasks/GET_TASK_HISTORY_NOTIFICATIONS_SUCCESS',
    GET_TASK_HISTORY_NOTIFICATIONS_ERROR = '@@tasks/GET_TASK_HISTORY_NOTIFICATIONS_ERROR',
    UPDATE_TASK_HISTORY_NOTIFICATIONS_FILTERS = '@@tasks/UPDATE_TASK_HISTORY_NOTIFICATIONS_FILTERS',
    UPDATE_TASK_HISTORY_NOTIFICATIONS_SORT_BY = '@@tasks/UPDATE_TASK_HISTORY_NOTIFICATIONS_SORT_BY',

    SET_TASK_HISTORY_NOTIFICATIONS_SELECTED_ID_REQUEST = '@@tasks/SET_TASK_HISTORY_NOTIFICATIONS_SELECTED_ID_REQUEST',
    SET_TASK_HISTORY_NOTIFICATIONS_SELECTED_ID_SUCCESS = '@@tasks/SET_TASK_HISTORY_NOTIFICATIONS_SELECTED_ID_SUCCESS',
    GET_TASK_HISTORY_NOTIFICATION_DATA_REQUEST = '@@tasks/GET_TASK_HISTORY_NOTIFICATION_DATA_REQUEST',
    GET_TASK_HISTORY_NOTIFICATION_DATA_SUCCESS = '@@tasks/GET_TASK_HISTORY_NOTIFICATION_DATA_SUCCESS',
    GET_TASK_HISTORY_NOTIFICATION_DATA_ERROR = '@@tasks/GET_TASK_HISTORY_NOTIFICATION_DATA_ERROR',
    CLEAR_TASK_HISTORY_NOTIFICATION_DATA_SUCCESS = '@@tasks/CLEAR_TASK_HISTORY_NOTIFICATION_DATA_SUCCESS',

    GET_TASK_HISTORY_DELIVERY_REPORT_SUCCESS = '@@tasks/GET_TASK_HISTORY_DELIVERY_REPORT_SUCCESS',
    GET_TASK_HISTORY_DELIVERY_REPORT_ERROR = '@@tasks/GET_TASK_HISTORY_DELIVERY_REPORT_ERROR',
    GET_TASK_HISTORY_DELIVERY_REPORT_REQUEST = '@@tasks/GET_TASK_HISTORY_DELIVERY_REPORT_REQUEST',
    UPDATE_TASK_HISTORY_DELIVERY_REPORT_FILTERS = '@@tasks/UPDATE_TASK_HISTORY_DELIVERY_REPORT_FILTERS',
    UPDATE_TASK_HISTORY_DELIVERY_REPORT_SORT_BY = '@@tasks/UPDATE_TASK_HISTORY_DELIVERY_REPORT_SORT_BY',

    // Invoices
    GET_TASK_HISTORY_NOTIFICATION_INVOICES_REQUEST = '@@tasks/GET_TASK_HISTORY_NOTIFICATION_INVOICES_REQUEST',
    GET_TASK_HISTORY_NOTIFICATION_INVOICES_SUCCESS = '@@tasks/GET_TASK_HISTORY_NOTIFICATION_INVOICES_SUCCESS',
    GET_TASK_HISTORY_NOTIFICATION_INVOICES_ERROR = '@@tasks/GET_TASK_HISTORY_NOTIFICATION_INVOICES_ERROR',
    UPDATE_TASK_HISTORY_NOTIFICATION_INVOICES_FILTERS = '@@tasks/UPDATE_TASK_HISTORY_NOTIFICATION_INVOICES_FILTERS',
    UPDATE_TASK_HISTORY_NOTIFICATION_INVOICES_SORT_BY_AND_STATE = '@@tasks/UPDATE_TASK_HISTORY_NOTIFICATION_INVOICES_SORT_BY_AND_STATE',

    // Tasks
    GET_TASK_HISTORY_NOTIFICATION_TASKS_REQUEST = '@@tasks/GET_TASK_HISTORY_NOTIFICATION_TASKS_REQUEST',
    GET_TASK_HISTORY_NOTIFICATION_TASKS_SUCCESS = '@@tasks/GET_TASK_HISTORY_NOTIFICATION_TASKS_SUCCESS',
    GET_TASK_HISTORY_NOTIFICATION_TASKS_ERROR = '@@tasks/GET_TASK_HISTORY_NOTIFICATION_TASKS_ERROR',
    UPDATE_TASK_HISTORY_NOTIFICATION_TASKS_FILTERS = '@@tasks/UPDATE_TASK_HISTORY_NOTIFICATION_TASKS_FILTERS',
    UPDATE_TASK_HISTORY_NOTIFICATION_TASKS_SORT_BY = '@@tasks/UPDATE_TASK_HISTORY_NOTIFICATION_TASKS_SORT_BY',
    GET_TASK_CUSTOMER_CUSTOM_FIELDS_FOR_COMPANY_REQUEST = '@@tasks/GET_TASK_CUSTOMER_CUSTOM_FIELDS_FOR_COMPANY_REQUEST',
    GET_TASK_INVOICE_CUSTOM_FIELDS_FOR_COMPANY_REQUEST = '@@tasks/GET_TASK_INVOICE_CUSTOM_FIELDS_FOR_COMPANY_REQUEST',
    BULK_SAVE_TASK_DETAILS_REQUEST = '@@tasks/BULK_SAVE_TASK_DETAILS_REQUEST',
    BULK_SAVE_TASK_DETAILS_RESPONSE = '@@tasks/BULK_SAVE_TASK_DETAILS_RESPONSE',
    
    GET_TASK_EDIT_PROPERTIES_REQUEST = '@@tasks/GET_TASK_EDIT_PROPERTIES_REQUEST',
    GET_WORKFLOW_STEP_OPTIONS_REQUEST = '@@tasks/GET_WORKFLOW_STEP_OPTIONS_REQUEST',

    BULK_ASSIGN_TASK_TO_USER_REQUEST = '@@tasks/BULK_ASSIGN_TASK_TO_USER_REQUEST',
    BULK_ASSIGN_TASK_TO_USER_RESPONSE = '@@tasks/BULK_ASSIGN_TASK_TO_USER_RESPONSE',

    TASK_ACTION_PAYMENT_VERIFICATION_TASK_REQUEST = '@@tasks/TASK_ACTION_PAYMENT_VERIFICATION_TASK_REQUEST',
    TASK_ACTION_PAYMENT_RA_LINK_TASK_REQUEST = '@@tasks/TASK_ACTION_PAYMENT_RA_LINK_TASK_REQUEST',
    TASK_ACTION_REQUEST_RA_TASK_REQUEST = '@@tasks/TASK_ACTION_REQUEST_RA_TASK_REQUEST',

    // Notification job status
    GET_TASK_HISTORY_QUEUED_TASKS_COUNT_REQUEST = '@@tasks/GET_TASK_HISTORY_QUEUED_TASKS_COUNT_REQUEST',
    GET_TASK_HISTORY_BATCH_STATUS_REQUEST = '@@tasks/GET_TASK_HISTORY_BATCH_STATUS_REQUEST',
    DOWNLOAD_MERGED_PDF_REQUEST = '@@tasks/DOWNLOAD_MERGED_PDF_REQUEST',
    DOWNLOAD_MERGED_PDF_STATUS_REQUEST = '@@tasks/DOWNLOAD_MERGED_PDF_STATUS_REQUEST',

    // Changed tasks
    GET_TASK_HISTORY_CHANGED_TASKS_REQUEST = '@@tasks/GET_TASK_HISTORY_CHANGED_TASKS_REQUEST',
    GET_TASK_HISTORY_CHANGED_TASKS_SUCCESS = '@@tasks/GET_TASK_HISTORY_CHANGED_TASKS_SUCCESS',
    GET_TASK_HISTORY_CHANGED_TASKS_ERROR = '@@tasks/GET_TASK_HISTORY_CHANGED_TASKS_ERROR',

    GET_TASK_HISTORY_TASKS_FAILED_REQUEST = '@@tasks/GET_TASK_HISTORY_TASKS_FAILED_REQUEST',
    GET_TASK_HISTORY_TASKS_FAILED_SUCCESS = '@@tasks/GET_TASK_HISTORY_TASKS_FAILED_SUCCESS',
    GET_TASK_HISTORY_TASKS_FAILED_ERROR = '@@tasks/GET_TASK_HISTORY_TASKS_FAILED_ERROR',

    GET_TASK_HISTORY_CHANGED_TASKS_DATA_REQUEST = '@@tasks/GET_TASK_HISTORY_CHANGED_TASKS_DATA_REQUEST',
    GET_TASK_HISTORY_CHANGED_TASKS_DATA_SUCCESS = '@@tasks/GET_TASK_HISTORY_CHANGED_TASKS_DATA_SUCCESS',
    GET_TASK_HISTORY_CHANGED_TASKS_DATA_ERROR = '@@tasks/GET_TASK_HISTORY_CHANGED_TASKS_DATA_ERROR',

    GET_ACTIONED_TICKET_DETAILS_REQUEST = '@@tasks/GET_ACTIONED_TICKET_DETAILS_REQUEST',
    GET_ACTIONED_PAYMENT_PLAN_DETAILS_REQUEST = '@@tasks/GET_ACTIONED_PAYMENT_PLAN_DETAILS_REQUEST',
    GET_ACTIONED_PAYMENT_DETAILS_REQUEST = '@@tasks/GET_ACTIONED_PAYMENT_DETAILS_REQUEST',
    GET_ACTIONED_PAYMENT_BEHAVIOUR_INSIGHT_DETAILS_REQUEST = '@@tasks/GET_ACTIONED_PAYMENT_BEHAVIOUR_INSIGHT_DETAILS_REQUEST',

    // Actioned tasks
    GET_TASK_HISTORY_ACTIONED_TASKS_REQUEST = '@@tasks/GET_TASK_HISTORY_ACTIONED_TASKS_REQUEST',

    GET_TASK_HISTORY_ACTIONED_TASKS_DATA_REQUEST = '@@tasks/GET_TASK_HISTORY_ACTIONED_TASKS_DATA_REQUEST',

    // Collections
    GET_TASK_HISTORY_COLLECTIONS_DATA_REQUEST = '@@tasks/GET_TASK_HISTORY_COLLECTIONS_DATA_REQUEST',
    GET_TASK_HISTORY_COLLECTIONS_DATA_SUCCESS = '@@tasks/GET_TASK_HISTORY_COLLECTIONS_DATA_SUCCESS',
    GET_TASK_HISTORY_COLLECTIONS_DATA_ERROR = '@@tasks/GET_TASK_HISTORY_COLLECTIONS_DATA_ERROR',

    GET_TASK_HISTORY_COLLECTION_TASKS_REQUEST = '@@tasks/GET_TASK_HISTORY_COLLECTION_TASKS_REQUEST',
    GET_TASK_HISTORY_COLLECTION_TASKS_SUCCESS = '@@tasks/GET_TASK_HISTORY_COLLECTION_TASKS_SUCCESS',
    GET_TASK_HISTORY_COLLECTION_TASKS_ERROR = '@@tasks/GET_TASK_HISTORY_COLLECTION_TASKS_ERROR',

    SET_TASK_HISTORY_COLLECTIONS_TASK_SELECTED_ID_REQUEST = '@@tasks/SET_TASK_HISTORY_COLLECTIONS_TASK_SELECTED_ID_REQUEST',
    SET_TASK_HISTORY_COLLECTIONS_TASK_SELECTED_ID_SUCCESS = '@@tasks/SET_TASK_HISTORY_COLLECTIONS_TASK_SELECTED_ID_SUCCESS',

    GET_TASK_HISTORY_COLLECTIONS_TASK_DATA_REQUEST = '@@tasks/GET_TASK_HISTORY_COLLECTIONS_TASK_DATA_REQUEST',
    GET_TASK_HISTORY_COLLECTIONS_TASK_DATA_SUCCESS = '@@tasks/GET_TASK_HISTORY_COLLECTIONS_TASK_DATA_SUCCESS',
    GET_TASK_HISTORY_COLLECTIONS_TASK_DATA_ERROR = '@@tasks/GET_TASK_HISTORY_COLLECTIONS_TASK_DATA_ERROR',
    CLEAR_TASK_HISTORY_COLLECTIONS_TASK_DATA_SUCCESS = '@@tasks/CLEAR_TASK_HISTORY_COLLECTIONS_TASK_DATA_SUCCESS',

    // Invoices
    GET_TASK_HISTORY_COLLECTIONS_TASK_INVOICES_REQUEST = '@@tasks/GET_TASK_HISTORY_COLLECTIONS_TASK_INVOICES_REQUEST',
    GET_TASK_HISTORY_COLLECTIONS_TASK_INVOICES_SUCCESS = '@@tasks/GET_TASK_HISTORY_COLLECTIONS_TASK_INVOICES_SUCCESS',
    GET_TASK_HISTORY_COLLECTIONS_TASK_INVOICES_ERROR = '@@tasks/GET_TASK_HISTORY_COLLECTIONS_TASK_INVOICES_ERROR',
    UPDATE_TASK_HISTORY_COLLECTIONS_TASK_INVOICES_FILTERS = '@@tasks/UPDATE_TASK_HISTORY_COLLECTIONS_TASK_INVOICES_FILTERS',
    UPDATE_TASK_HISTORY_COLLECTIONS_TASK_INVOICES_SORT_BY_AND_STATE = '@@tasks/UPDATE_TASK_HISTORY_COLLECTIONS_TASK_INVOICES_SORT_BY_AND_STATE',

    ESCALATE_TO_COLLECTIONS = '@@tasks/ESCALATE_TO_COLLECTIONS',

    TASK_ADD_COMMENT_REQUEST = '@@tasks/TASK_ADD_COMMENT_REQUEST',

    // Payments
    MANUALLY_ALLOCATE_PAYMENT_REQUEST = '@@tasks/MANUALLY_ALLOCATE_PAYMENT',
    MANUALLY_ALLOCATE_PAYMENT_WITH_OVERPAYMENT_REQUEST = '@@tasks/MANUALLY_ALLOCATE_PAYMENT_WITH_OVERPAYMENT_REQUEST',
    //Payment Allocation
    GET_TRIGGERS_FOR_WORKFLOW = '@@tasks/GET_TRIGGERS_FOR_WORKFLOW',

    //Payment Remittance Advice
    GET_REMITTANCE_ADVICES_REQUEST = '@@tasks/GET_REMITTANCE_ADVICES_REQUEST',
    GET_REMITTANCE_ADVICES_SUCCESS = '@@tasks/GET_REMITTANCE_ADVICES_SUCCESS',

    //PaymentBehaviorInsights
    TASK_PAYMENT_BEHAVIOR_INSIGHTS_REQUEST = '@@tasks/TASK_PAYMENT_BEHAVIOR_INSIGHTS_REQUEST',

    // Task Viewings
    ADD_TASK_VIEWINGS_REQUEST = '@@tasks/ADD_TASK_VIEWINGS_REQUEST',
    DELETE_TASK_VIEWINGS_REQUEST = '@@tasks/DELETE_TASK_VIEWINGS_REQUEST',
    GET_TASK_VIEWER_DETAIL_FOR_USER_REQUEST = '@@tasks/GET_TASK_VIEWER_DETAIL_FOR_USER_REQUEST',
    GET_TASK_VIEWER_DETAIL_FOR_USER_ORGANISATION_REQUEST = '@@tasks/GET_TASK_VIEWER_DETAIL_FOR_USER_ORGANISATION_REQUEST',
    GET_TASK_VIEWER_DETAIL_FOR_USER_SUCCESS = '@@tasks/GET_TASK_VIEWER_DETAIL_FOR_USER_SUCCESS',
    GET_TASK_VIEWER_DETAIL_FOR_USER_ERROR = '@@tasks/GET_TASK_VIEWER_DETAIL_FOR_USER_ERROR',

    GET_TASK_WORKFLOWS_REQUEST = '@@tasks/GET_TASK_WORKFLOWS_REQUEST',
    GET_TASK_WORKFLOWS_SUCCESS = '@@tasks/GET_TASK_WORKFLOWS_SUCCESS',
    GET_TASK_WORKFLOWS_ERROR = '@@tasks/GET_TASK_WORKFLOWS_ERROR',
    
    // Phone Call
    ACTION_PHONE_CALL_REQUEST = '@@tasks/ACTION_PHONE_CALL_REQUEST',
    GET_ACTIONED_PHONE_CALL_DATA_REQUEST = '@@tasks/GET_ACTIONED_PHONE_CALL_DATA_REQUEST'
}

export interface TasksState {
    readonly activeTasks: {
        readonly loading: boolean;
        readonly saveTaskDataLoading: boolean;
        readonly errorMessages: string[];
        readonly data: Task[];
        readonly pageData: PageData;
        readonly filters: GetTasksRequestPayload['filters'];
        readonly sortBy: GetTasksRequestPayload['sortBy'];
        readonly sortAscending: boolean;
        readonly taskStatus: string;
        readonly taskTableFilter: string | undefined;
        readonly actionFilterOptions: DynamicObject;
        readonly actionFilterOptionsLoading: boolean;
        readonly compactView: boolean;
        readonly compactViewLoading: boolean;
        readonly viewingUser: {
            user?: User;
            loading: boolean
        };
        readonly activeData: {
            loading: boolean;
            record: {};
            selectedId: string | null;
            errorMessages: string[];
            invoices: {
                loading: boolean;
                errorMessages: string[];
                data: Invoice[];
                pageData: PageData;
                filters: {};
                sortBy: string;
                sortAscending: boolean;
                invoiceState: string;
            };
            schedule: {
                readonly loading: boolean;
                readonly errorMessages: string[];
                readonly data: PaymentPlanSchedule[];
                readonly pageData: PageData;
                readonly filters: GetPaymentPlanDataScheduleRequestPayload['filters'];
                readonly sortBy: GetPaymentPlanDataScheduleRequestPayload['sortBy'];
                readonly sortAscending: boolean;
            };
        };
        readonly notifyCustomersLoading: boolean;
        readonly toggledExpandStatusIds: string[];
    };
    readonly taskHistory: {
        readonly loading: boolean;
        readonly errorMessages: string[];
        readonly data: TaskBatch[];
        readonly pageData: PageData;
        readonly filters: GetTaskHistoryRequestPayload['filters'];
        readonly sortBy: GetTaskHistoryRequestPayload['sortBy'];
        readonly sortAscending: boolean;
        readonly taskTableFilter: string | undefined;
        readonly activeData: {
            loading: boolean;
            record: {};
            selectedId: string | null;
            errorMessages: string[];
            notifications: {
                loading: boolean;
                errorMessages: string[];
                data: TaskHistoryNotification[];
                pageData: PageData;
                filters: any;
                sortBy: any;
                sortAscending: boolean;
                activeData: {
                    loading: boolean;
                    selectedId: string | null;
                    errorMessages: string[];
                    record: {};
                    invoices: {
                        loading: boolean;
                        errorMessages: string[];
                        data: Invoice[];
                        pageData: PageData;
                        filters: {};
                        sortBy: string;
                        sortAscending: boolean;
                        invoiceState: string;
                    };
                    tasks: {
                        loading: boolean;
                        errorMessages: string[];
                        data: TaskHistory[];
                        pageData: PageData;
                        filters: {};
                        sortBy: string;
                        sortAscending: boolean;
                    };
                };
            };
            changedTasks: {
                activeData: {
                    loading: boolean;
                    record: {};
                    errorMessages: string[];
                };
                list: {
                    loading: boolean;
                    errorMessages: string[];
                    data: Task[];
                    pageData: PageData;
                };
            };
            collections: {
                activeData: {
                    loading: boolean;
                    record: {};
                    errorMessages: string[];
                };
                actionedTasks: {
                    loading: boolean;
                    errorMessages: string[];
                    data: Task[];
                    pageData: PageData;
                    activeData: {
                        loading: boolean;
                        selectedId: string | null;
                        errorMessages: string[];
                        record: {};
                    };
                };
            };
            failedList: {
                loading: boolean;
                errorMessages: string[];
                data: Task[];
                pageData: PageData;
            };
            detailsDelivery: {
                loading: boolean;
                pageData: PageData;
                filters: any;
                sortBy: string;
                sortAscending: boolean;
                data: TaskDeliveryReport[];
                activeData: {
                    loading: boolean;
                    selectedId: string | null;
                    errorMessages: string[];
                    record: {};
                };
            }
        };
    };
    readonly taskWorkflows: {
        readonly loading: boolean;
        readonly errorMessages: string[];
        readonly data: DynamicObject[];
    };
}

export interface Task {
    readonly Id: string;
    readonly CompanyId: string;
    readonly Type: string;
    readonly Automation: string;
    readonly ActionDate: string;
    readonly State: string;
    readonly Workflow: Workflow;
    readonly Customer: Customer;
    readonly Invoice: Invoice;
    readonly ClosedDateTime: string;
    readonly Ticket: Ticket;
    readonly AssignedUser: AssignedUser;
    readonly AssignedEmailAddress: string;
    readonly ConversationLine: ConversationLine;
    readonly Payment: Payment;
    readonly PaymentPlanSchedule: PaymentPlanSchedule;
    readonly PaymentPlan: PaymentPlan;
    readonly ActionDateMovedBy: string;
    readonly RemittanceAdvice: RemittanceAdvice;
    readonly CommonInvoiceCustomFields: CustomField[];
    readonly Company: Company;
}

export interface AssignedUser {
    readonly UserId: string;
    readonly GivenName: string;
    readonly FamilyName: string;
}

export interface TaskBatch {
    readonly BatchId: string;
    readonly CompanyId: string;
    readonly UserId: string;
    readonly Type: string;
    readonly State: string;
    readonly Automation: string;
    readonly CreatedDateTime: string;
    readonly BatchCount: number;
    readonly Action: string;
}

interface Workflow {
    readonly WorkflowId: string;
    readonly StateName: string;
}

export interface GetTasksRequestPayload {
    callback?: (response: any) => void;
    filters: {};
    sortBy: typeof tasksSortByOptions[number]['value'];
    taskIds?: string[];
    excludeTasks?: boolean;
    sortAscending: boolean;
    pageSize: number;
    currentPage: number;
    taskTableFilter?: string;
    lockedDeliveryMethod?: boolean;
    isUsingCloudImportType?: boolean;
    usingMultipleWorkflow?: boolean;
    isPaymentPlanEnabled?: boolean;
    recordLimit?: number;
    region?: string;
}

export interface GetTaskViewerDetailForUserRequestPayload {
    filters: {};
    taskIds?: string[];
    excludeTasks?: boolean;
    taskTableFilter?: string;
    sortBy: typeof tasksSortByOptions[number]['value'];
    sortAscending: boolean;
    lockedDeliveryMethod?: boolean;
    isUsingCloudImportType?: boolean;
    usingMultipleWorkflow?: boolean;
    isPaymentPlanEnabled?: boolean;
    recordLimit?: number;
    silent?: boolean;
    region?: string;
}

export interface ChangeTaskViewingRequestPayload {
    callback?: (response: any) => void;
    filters: {};
    taskIds?: string[];
    excludeTasks?: boolean;
    taskTableFilter?: string;
    sortBy: string;
    sortAscending: boolean;
    lockedDeliveryMethod?: boolean;
    isUsingCloudImportType?: boolean;
    usingMultipleWorkflow?: boolean;
    isPaymentPlanEnabled?: boolean;
    recordLimit?: number;
}

export interface NotifyCustomersRequestPayload {
    filter: {};
    taskIds: string[] | number[];
    excludeTasks: boolean;
    callback?: (response: ResponseModalObject) => void;
    sortBy: string;
    sortAscending: boolean;
    recordLimit?: number;
    isPaymentPlanEnabled?: boolean;
}

/**
 * Task History section
 */

export interface GetTaskHistoryRequestPayload {
    filters: {};
    sortBy: typeof taskHistorySortByOptions[number]['value'];
    sortAscending: boolean;
    pageSize: number;
    currentPage: number;
    taskTableFilter?: string;
}

// Task History Notifications
export interface GetTaskHistoryNotificationsRequestPayload {
    filters: {};
    sortBy: typeof taskHistorySortByOptions[number]['value'];
    sortAscending: boolean;
    pageSize: number;
    currentPage: number;
    isUsingCloudImportType?: boolean;
}

export interface TaskHistoryNotification {
    readonly GroupId: number;
    readonly WorkflowId: string;
    readonly BatchId: string;
    readonly StateName: string;
    readonly State: string;
    readonly CustomerCode: string;
    readonly Customer: Customer;
    readonly CommunicationId: string;
    readonly Contact: number;
    readonly Comment?: string;
}

// Task History delivery report
export interface GetTaskHistoryDeliveryReportRequestPayload {
    filters: {};
    sortBy: typeof taskHistorySortByOptions[number]['value'];
    sortAscending: boolean;
    pageSize: number;
    currentPage: number;
    isUsingCloudImportType: boolean;
}

export interface TaskDeliveryReport {
    readonly GroupId: number;
    readonly WorkflowId: string;
    readonly BatchId: string;
    readonly StateName: string;
    readonly State: string;
    readonly CustomerCode: string;
    readonly Customer: Customer;
    readonly CommunicationId: string;
    readonly Contact: number;
    readonly Comment?: string;
    readonly DeliveryDetails: [DeliveryDetails];
    
}

export interface DeliveryDetails {
    readonly Type: string;
    readonly Comment: string;
    readonly State: string;
    readonly Reason: string;
}

export interface GetExtendedInvoicesRequestPayload {
    filters: {};
    sortBy: typeof commonInvoiceListInvoicesSortOptions[number]['value'];
    sortAscending: boolean;
    invoiceState: string;
    pageSize: number;
    currentPage: number;
}

export interface GetNotificationTasksRequestPayload {
    filters: {};
    sortBy: typeof commonInvoiceListInvoicesSortOptions[number]['value'];
    sortAscending: boolean;
    pageSize: number;
    currentPage: number;
}

export interface TaskHistory {
    Id: string;
    UserId: string;
    State: string;
    Invoice: {
        Number: string;
    };
    Customer: Customer;
    ActionDate: string;
    ClosedDateTime: string;
    Type: string;
    Automation: string;
    Workflow: {
        StateName: string;
    };
    Ticket: Ticket;
    PaymentPlanSchedule: PaymentPlanSchedule;
    PaymentPlan: PaymentPlan;
}

export interface Communication {
    Contents: {
        TemplateS3Key: string;
        File: {
            Id: string;
            FileLocation: string;
        };
        Type: number;
    };
    Customer: Customer;
    Invoices: [Invoice];
    Contacts: Contact;
    Company: Company;
    ContactMethod: number;
    CreatedDateTime: string;
    WorkflowStateName: string;
}

// Task History Changed tasks
export interface GetTaskHistoryChangedTasksRequestPayload {
    filters: {};
    // sortBy: typeof taskHistorySortByOptions[number]['value'];
    // sortAscending: boolean;
    pageSize: number;
    currentPage: number;
}

export interface GetTaskHistoryCollectionTasksRequestPayload {
    filters: {};
    // sortBy: typeof taskHistorySortByOptions[number]['value'];
    // sortAscending: boolean;
    pageSize: number;
    currentPage: number;
}

// Edit tasks
export interface BulkSaveTaskDetailsUpdateRequestPayload {
    filter: {};
    taskIds: string[] | number[];
    excludeTasks: boolean;
    sortBy: string;
    sortAscending: boolean;
    recordLimit?: number;
    isPaymentPlanEnabled?: boolean;
    taskObject: {};
    callback?: (response: any) => void;
}

export interface GetTaskPropertiesRequestPayload {
    filter: {};
    taskIds: string[] | number[];
    excludeTasks: boolean;
    sortBy: string;
    sortAscending: boolean;
    recordLimit?: number;
    isPaymentPlanEnabled?: boolean;
    callback?: (response: DynamicObject) => void;
}

export interface GetWorkflowStepOptionsRequestPayload {
    workflowId: string;
    callback?: (response: any[]) => void;
}

// Failed tasks
export interface GetTaskHistoryTasksFailedRequestPayload {
    filters: {};
    // sortBy: typeof taskHistorySortByOptions[number]['value'];
    // sortAscending: boolean;
    pageSize: number;
    currentPage: number;
}

export interface TaskAddCommentRequestPayload
    extends AddCommentCommonFilterPayload {
    taskIds: string[] | number[];
    excludeTasks: boolean;
    sortBy: typeof tasksSortByOptions[number]['value'];
    sortAscending: boolean;
    recordLimit?: number;
    isPaymentPlanEnabled?: boolean;
}

// Details Delivery Report
export interface GetTaskDetailsDeliveryRequestPayload {
    filters: {
        Status?: number,
        NotificationType?: number,
        DeliveryType?: number,
        DeliveryState?: number
    };
    sortBy: string;
    sortAscending: boolean;
    pageSize: number;
    currentPage: number;
}

// Task History Actioned Tasks
export interface GetTaskHistoryActionedTasksRequestPayload {
    filters: {};
    pageSize: number;
    currentPage: number;
}

export interface TaskActionPaymentVerificationTaskRequestPayload {
    task: {
        Id?: string
    };
    customer?: string;
    multipleCustomer?: boolean;
    IsUnverify?: boolean;
    CreateBankDescriptor?: boolean;
    callback?: (response: any) => void;
}

export interface TaskActionPaymentRALinkTaskRequestPayload {
    task: {
        Id?: string
    };
    remittanceAdvice?: {
        Id?: string
        MarkAsOverpayment?: boolean
    };
    callback?: (response: any) => void;
}

export interface TaskActionRequestRATaskRequestPayload {
    task: {
        Id?: string
    };
    emailAddress: string
    callback?: (response: any) => void;
}

export interface GetRemittanceAdvicesRequestPayload {
    CompanyId: string,
    CustomerId: string,
    RemittanceAdviceState: number, 
    PaidDateMin: string,
    PaidDateMax: string,
    PaidAmountMin: string,
    PaidAmountMax: string,
    SortField: string
    Ascending: boolean
    AmountType?: string
    Skip: number
    PageSize: number
    callback?: (response: any) => void;
}

export interface RemittanceAdvices extends DynamicObject {
    readonly Id: string,
    readonly CompanyId: string,
    readonly Customer: Customer
    readonly Status: number
    readonly ReceivedDate: string
    readonly LocalReceivedDate: string
    readonly PaidDate: string
    readonly LocalPaidDate: string
    readonly Amount: number
    readonly MultipleCustomer: boolean
    readonly ConversationLine: ConversationLine
    readonly Uri: string
    readonly Attachments: Attachment
}

export interface WorkflowTransition {
    Trigger: string;
    DataCapture: DataCapture | null;
}
export interface DataCapture {
    CaptureDateTime: boolean;
    DateTimeLabel: string;
    CaptureComment: boolean;
    CommentLabel: string;
}

export interface TaskWorkflowFilter {
    WorkflowId: string;
    States?: string[];
}

export interface GetCustomFieldForCompanyRequestPayload {
    callback?: (response: any) => void;
    CompanyId: string;
}
export interface TaskActionPhoneCallTaskRequest {
    filter: {};
    taskIds: string[] | number[];
    excludeTasks: boolean;
    recordLimit?: number;
    PromiseToPayDate: string;
    Comment: string;
}

export interface GetActionedPhoneCallDataRequest {
    BatchId: string;
    IsUsingCloudImportType?: boolean;
    UsingCustomerWorkflow?: boolean;
}
