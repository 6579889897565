/**
 * File responsible for the content when clicking `Allocate to invoice` in credits management pages.
 */

import { Button, Checkbox, Col, Form, Input, InputNumber, Modal, Row } from 'antd';
import { get, capitalize, isEmpty, map, first, filter } from 'lodash';
import React, { Suspense, lazy, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getPopoverContainer, limitOnlyNumber, getTranslatedText } from '../../utils/commonFunctions';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import { withDateFormatHandler } from '../common/DateFormatHandler';
import { getCustomerUILabel } from '../../store/customers/sagas';
import InputAutoCompleteWithButton from '../common/InputAutoCompleteWithButton';
import TextArea from 'antd/lib/input/TextArea';
import { DynamicObject } from '../../utils/commonInterfaces';
import { CreditVM, RequestEditCreditManuallyPayload } from '../../store/credits/types';
import { ApplicationState } from '../../store';
import { Customer } from '../../store/customers/types';
import { v4 as uuidv4 } from 'uuid';
import { editCreditManuallyRequestAction } from '../../store/credits/actions';
import { Payment } from '../../store/payments/types';
import { RemittanceAdvice } from '../../store/remittanceAdvices/types';
import CurrencyInput from '../common/CurrencyInput';


const formFieldNames = {
    Name: 'Name',
    Description: 'Description',
    CustomerCode: 'CustomerCode',
    Amount: 'Amount',
    Percentage: 'Percentage',
    CreditType: 'CreditType',
    UsePercentage: 'UsePercentage',
    Recurring: "Recurring"
};

const ModalWithSpinner = lazy(() => import('../common/ModalWithSpinner'));

const { Item: FormItem } = Form;
interface ICombinedProps extends IProps {
    valueChanges?: any;
    setValueChanges: (valueChanges?: any) => void;
}

interface IProps {
    readonly containerRef?: any;
    readonly visible: boolean;
    readonly isEditViaManamentPage?: boolean;
    readonly isEditMode: boolean;
    readonly editCredit?: Partial<CreditVM>;
    readonly existingCreditList?: Partial<CreditVM>[];
    readonly closePanel?: (refetchList?: boolean) => void;
    readonly form?: any;
    readonly handleAddCredit?: (creditVM: CreditVM) => void;
    readonly handleEditCredit?: (creditVM: CreditVM) => void;
    readonly disableCustomer?: boolean;
    readonly payment?: Payment;
    readonly remittanceAdvice?: RemittanceAdvice;
    readonly formatToParts?: (amount: number) => Intl.NumberFormatPart[];
}

const CreateCreditItemManuallyDrawerContent: React.FC<ICombinedProps> = ({
    containerRef,
    visible,
    isEditViaManamentPage,
    isEditMode,
    editCredit,
    existingCreditList,
    closePanel,
    form,
    handleAddCredit,
    handleEditCredit,
    valueChanges,
    setValueChanges,
    disableCustomer,
    payment,
    remittanceAdvice,
    formatToParts
}: ICombinedProps) => {
    
    const supportCashAllocation = useSelector((state: ApplicationState) =>
        get(state.companies.selectedUserCompany, 'Company.SupportCashAllocation')
    );

    const [hasError, setHasError] = useState<boolean>(true);
    const [customerCode, setCustomerCode] = useState<string | undefined>('');
    const [customerSearch, setCustomerSearch] = useState<string>('');
    const {
        getFieldDecorator,
        validateFields,
        resetFields,
        getFieldValue,
        setFieldsValue
    } = form;

    const dispatch = useDispatch();

    const customerLabel = useSelector(getCustomerUILabel);

    const isCreateBtnDisabled = hasError;
    const isRebate = getFieldValue(formFieldNames.CreditType);
    const usePercentage = getFieldValue(formFieldNames.UsePercentage);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);

    /**
     * Function called when `Cancel` button is clicked inside Reconcile report panel.
     */
    const handleClosePanel = () => {
        if (closePanel) closePanel();
    };

    /**
     * Function that listens if panel is closed.
     * If closed, the form fields and values will reset.
     */
    const listenForClosingPanel = () => {
        if (!visible) {
            resetFields();
        }
        else {
            setFieldsValue({
                [formFieldNames.CustomerCode]: undefined
            });
            setCustomerCode(undefined);
            setCustomerSearch('');
        }
    };

    useEffect(listenForClosingPanel, [visible]);

    useEffect(() => {
        if (payment && visible) {
            setFieldsValue({
                [formFieldNames.CustomerCode]: get(payment, 'Customer.CustomerCode')
            });

            setValueChanges({
                [formFieldNames.CustomerCode]: true
            });

            updateCustomerCode(get(payment, 'Customer.CustomerCode'));
            setCustomerSearch(get(payment, 'Customer.DisplayName'));
        }
        else if (remittanceAdvice && visible) {
            setFieldsValue({
                [formFieldNames.CustomerCode]: get(remittanceAdvice, 'Customer.CustomerCode')
            });

            setValueChanges({
                [formFieldNames.CustomerCode]: true
            });

            updateCustomerCode(get(remittanceAdvice, 'Customer.CustomerCode'));
            setCustomerSearch(get(remittanceAdvice, 'Customer.DisplayName'));
        }
    }, [visible]);

    /**
     * Function called when submitting the form.
     */
    const handleSubmitForm = () => {
        validateFields((err: any, values: any) => {
            if (!err) {
                const credit: CreditVM = {
                    Id: isEditMode && editCredit ? editCredit.Id : uuidv4(),
                    Type: (!supportCashAllocation || !isRebate) ? 'Credit' : 'Rebate',
                    CreditCode: getFieldValue(formFieldNames.Name),
                    Description: getFieldValue(formFieldNames.Description),
                    UsePercentage: getFieldValue(formFieldNames.UsePercentage),
                    Percentage: getFieldValue(formFieldNames.UsePercentage) ? 1 : 0,
                    OriginalAmount: getFieldValue(formFieldNames.Amount),
                    CreatedType: 'Manually',
                    Customer: {
                        CustomerCode: (customerCode || ''),
                        DisplayName: customerSearch
                    } as Customer,
                    IsRecurring: getFieldValue(formFieldNames.Recurring)
                } as CreditVM;

                if (isEditViaManamentPage) {
                    handleSaveCredit(credit);
                } else {
                    if (isEditMode && handleEditCredit) {
                        handleEditCredit(credit);
                    } else if (handleAddCredit) {
                        handleAddCredit(credit);
                    }

                    handleClosePanel();
                }
            }
        });
    };

    /**
     * Function called when user submitting the form from the credit manament page.
     */
    const handleSaveCredit = (credit: Partial<CreditVM>) => {
        validateFields((err: any, values: any) => {
            if (!err) {
                setSubmitLoading(true);

                const requestCreateCreditManuallyPayload: RequestEditCreditManuallyPayload = {
                    Credit: credit,
                    callback: createCreditResponseModal
                };

                dispatch(editCreditManuallyRequestAction(requestCreateCreditManuallyPayload));
            }
        });
    };

    /**
     * Function responsible for showing the response modal after credit created.
     * @param param0 - object with success indicator and error message from api (if there's any)
     */
    const createCreditResponseModal = ({
        IsSuccess,
        Messages,
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
    }) => {
        setSubmitLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: getTranslatedText('Success'),
                content:
                    getTranslatedText('Credit edited successfully!'),
                onOk: () => {
                    if (closePanel) closePanel(true);
                },
                getContainer: () => getPopoverContainer(containerRef),
                okText: getTranslatedText('OK')
            });
        } else {
            let errorMessageContent: any = getTranslatedText(`Failed to edit credit!`);
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{getTranslatedText(error)}</div>
                    )
                );
            }

            Modal.error({
                title: getTranslatedText('Error'),
                content: errorMessageContent,
                getContainer: () => getPopoverContainer(containerRef),
                okText: getTranslatedText('OK')
            });
        }
    };


    const updateCustomerCode = useMemo(() => (value: React.SetStateAction<string | undefined>) => {
        setFieldsValue({
            [formFieldNames.CustomerCode]: value
        });

        setCustomerCode(value);

        setValueChanges({
            [formFieldNames.CustomerCode]: true
        });
    }, [setValueChanges]);

    const validateForm = useMemo(() => (options?: {
        success?: (values: any) => void
    }) => {
        validateFields({ suppressWarning: true }, (err: any, values: DynamicObject) => {
            if (!err) {
                setHasError(false);
            } else {
                setHasError(true);
            }
        });
    }, [validateFields]);

    useEffect(() => {
        validateForm();
    }, [valueChanges, validateForm, visible]);

    useEffect(() => {
        if (isEditMode) {
            updateCustomerCode(get(editCredit, 'Customer.CustomerCode'));
            setCustomerSearch(get(editCredit, 'Customer.DisplayName'));

            setFieldsValue({
                [formFieldNames.Name]: get(editCredit, 'CreditCode'),
                [formFieldNames.Description]: get(editCredit, 'Description'),
                [formFieldNames.UsePercentage]: get(editCredit, 'UsePercentage'),
                [formFieldNames.CreditType]: get(editCredit, 'Type') === 'Rebate' ? true : false
            });
        }
    }, [isEditMode, editCredit]);

    useEffect(() => {
        if (isEditMode) {
            if (isRebate) {
                setFieldsValue({
                    [formFieldNames.UsePercentage]: get(editCredit, 'UsePercentage'),
                    [formFieldNames.Recurring]: get(editCredit, 'IsRecurring')
                })
            }
        }
    }, [isEditMode, isRebate]);

    useEffect(() => {
        if (isEditMode) {
            if (usePercentage) {
                setFieldsValue({
                    [formFieldNames.Percentage]: get(editCredit, 'Percentage'),
                })
            } else {
                setFieldsValue({
                    [formFieldNames.Amount]: get(editCredit, 'OriginalAmount'),
                })
            }
        }
    }, [isEditMode, usePercentage]);

    /**
     * Function called when user update the credit name.
     */
    const isDuplicatedCreditCode = (creditCode: string) => {        
        if (creditCode && existingCreditList) {
            if (isEditMode) {
                return existingCreditList.some(c => editCredit && c.Id !== editCredit.Id && c.CreditCode === creditCode);
            }
            else {
                return existingCreditList.some(c => c.CreditCode === creditCode);
            }
        }

        return false;
    };

    /**
    * Function that is called to validate the amount paid form field.
    * @param _rule
    * @param value
    */
    const checkAmount = async (_rule: any, value: any) => {
        const usedValue =
            value || getFieldValue(formFieldNames.Amount);

        if (!usedValue || !(usedValue > 0)) {
            throw new Error('Amount required!');
        }
    };

    /**
     * Common function for populating currency input box
     * @param key
     */
    const populateNumberFormatElement = (key: string) => (
        <CurrencyInput
            onChange={(floatValue: undefined | number) => {
                setFieldsValue({ [key]: floatValue });
            }}
            currentValue={getFieldValue(key)}
        />
    );

    /**
     * Function responsible for populating the panel content.
     * Form fields.
     */
    const populatePanelContent = () => {
        return (
            <Form labelAlign='left' className="form-inline-mb-0" labelCol={{ span: 8 }}>
                <Row type="flex" align="middle">
                    {
                        supportCashAllocation &&
                        <Col span={24}>
                                <FormItem label={getTranslatedText("Rebate")} style={isEditViaManamentPage ? {display: 'none'} : {}}>
                                {getFieldDecorator(formFieldNames.CreditType, {
                                    valuePropName: 'checked',
                                    initialValue: false
                                })(
                                    <Checkbox />
                                )}
                            </FormItem>
                        </Col>
                    }
                    <Col span={24}>
                        <FormItem label={getTranslatedText("Name")}>
                            {getFieldDecorator(formFieldNames.Name, {
                                rules: [
                                    {
                                        required: true,
                                        message: getTranslatedText('Name required!'),
                                    },
                                    {
                                        validator: async (_rule: any, value: any) => {
                                            const creditCode = value || getFieldValue(formFieldNames.Name);
                                            if(isDuplicatedCreditCode(creditCode)) {
                                                throw new Error(getTranslatedText(`Duplicated Credit Name!`));
                                            }                                    
                                        }
                                    }
                                ]
                            })(<Input />)}
                        </FormItem>
                    </Col>
                    <Col span={24}>
                        <FormItem label={getTranslatedText("Description")}>
                            {getFieldDecorator(formFieldNames.Description)
                                (<TextArea rows={4} />)}
                        </FormItem>
                    </Col>
                    <Col span={24}>
                        <FormItem label={getTranslatedText(capitalize(customerLabel))}>
                            {getFieldDecorator(formFieldNames.CustomerCode, {
                                rules: [
                                    {
                                        required: true,
                                        message: getTranslatedText(`${capitalize(customerLabel)} required!`),
                                    },
                                ],
                            })(
                                <Input hidden />
                            )}<InputAutoCompleteWithButton
                                updateField={(value: any) => {
                                    if (!value && customerCode) {
                                        updateCustomerCode(undefined);
                                    }
                                    setCustomerSearch(value);
                                }}
                                onSelect={(value: any) => {
                                    const CustomerCode = get(value, 'CustomerCode');
                                    updateCustomerCode(CustomerCode);
                                }}
                                minimumLength={1}
                                stateValue={customerSearch}
                                placeholder={getTranslatedText("Search customer")}
                                keyField={'Id'}
                                queryName={'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER'}
                                filterField={'Customer'}
                                queryFilterName={'Customer'}
                                sortField={'Company name'}
                                responseName={'GetCustomersForCompany.Customers'}
                                labelField={'DisplayName'}
                                hasNoOkButton
                                loading={false}
                                readOnly={disableCustomer}
                            />
                        </FormItem>
                    </Col>
                    {
                        isRebate &&
                        <Col span={24}>
                                <FormItem label={getTranslatedText("Match from Remittance Advice")}>
                                {getFieldDecorator(formFieldNames.UsePercentage, {
                                    valuePropName: 'checked'
                                })(
                                    <Checkbox />
                                )}
                            </FormItem>
                        </Col>
                    }
                    {
                        !usePercentage &&
                        <Col span={24}>
                            <FormItem label={getTranslatedText("Amount")} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                                {getFieldDecorator(formFieldNames.Amount, {
                                    initialValue: 0.0,
                                    rules: [
                                        {
                                            required: true,
                                            message: getTranslatedText('Amount required!'),
                                            validator: checkAmount,
                                        }
                                    ]
                                })(
                                    populateNumberFormatElement(
                                        formFieldNames.Amount
                                    )
                                )}
                            </FormItem>
                        </Col>
                    }
                    {/* {
                        usePercentage &&
                        <Col span={24}>
                            <FormItem label={getTranslatedText("Percentage")}>
                                {getFieldDecorator(formFieldNames.Percentage, {
                                    rules: [
                                        {
                                            required: true,
                                            message: getTranslatedText('Percentage required!'),
                                        }
                                    ]
                                })(
                                    <InputNumber
                                        min={0.01}
                                        max={100}
                                        onKeyDown={limitOnlyNumber(true)}
                                    />
                                )}
                            </FormItem>
                        </Col>
                    } */}
                    <Col span={24}>
                        {
                            isRebate && <FormItem label={getTranslatedText("Recurring")}>
                            {getFieldDecorator(formFieldNames.Recurring, {
                                valuePropName: 'checked',
                                initialValue: false
                            })(
                                <Checkbox />
                            )}
                        </FormItem>}
                    </Col>
                </Row>
            </Form>
        );
    };

    return (
        <Row>
            <Col>
                <div>{populatePanelContent()}</div>
                <br />
                <Row>
                    <Col className="ta-right" span={24}>
                        <Button
                            className="mr-8"
                            type="primary"
                            onClick={handleSubmitForm}
                            disabled={isCreateBtnDisabled}
                        >
                            {getTranslatedText(`${isEditMode ? 'Save' : 'Add'}`)}
                        </Button>
                        <Button onClick={handleClosePanel}>{getTranslatedText('Cancel')}</Button>
                    </Col>
                </Row>
            </Col>
            {submitLoading && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle={getTranslatedText("Editing credit")}   
                        modalVisible={submitLoading}
                        displayMessage={getTranslatedText("Please wait while Editing credit . . .")} 
                        containerRef={containerRef}
                    />
                </Suspense>
            )}
        </Row>
    );
};

const CreateCreditItemManuallyDrawerContentWrapper: React.FC<IProps> = (props) => {
    const [valueChanges, setValueChanges] = useState<any>();

    const InnerForm = useMemo(() => {
        const CreateCreditItemManuallyDrawerContentForm = Form.create({
            name: 'create-credit-manually-drawer-content-form',
            onValuesChange(props, changedValues, allValues) {
                setValueChanges(changedValues);
            },
        })(CreateCreditItemManuallyDrawerContent);

        return withRouter(
            withNumberFormatHandler(
                withDateFormatHandler(CreateCreditItemManuallyDrawerContentForm)
            )
        );
    }, []);

    return <InnerForm {...props}
        valueChanges={valueChanges}
        setValueChanges={setValueChanges} />
};

export default CreateCreditItemManuallyDrawerContentWrapper;