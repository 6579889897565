/**
 * Component for populating the row item in the main table for Task history page.
 */

import { Col, Row } from 'antd';
import { get, isEmpty } from 'lodash';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { dateFormatDDMMMYYYYHHmmSpace } from '../../../constants/dateFormats';
import {
    paymentPlanApprovalTriggers,
    taskHistoryAutomation,
    taskHistoryBatchStates,
    taskHistoryBatchTypes,
} from '../../../constants/tasksSortAndFilters';
import '../../../pages/sales/sales.less';
import { ApplicationState } from '../../../store';
import { TaskBatch } from '../../../store/tasks/types';
import { withAutomationIconsHandler } from '../../common/AutomationIconsHandler';
import AvatarNameInitialsComponent from '../../common/AvatarNameInitialsComponent';
import { withDateFormatHandler } from '../../common/DateFormatHandler';
import { withNumberFormatHandler } from '../../common/NumberFormatHandler';
import { DynamicObject } from '../../../utils/commonInterfaces';
import { getTranslatedText, getUserFullName } from '../../../utils/commonFunctions';

interface IProps {
    readonly item: TaskBatch;
    readonly style: {};
    readonly onRowClick: (item: DynamicObject) => void;
    readonly formatCurrency?: (amount: number) => JSX.Element;
    readonly formatDateUTCToLocal?: (
        date: any,
        fromFormat?: string | null,
        toFormat?: string | null
    ) => string;
    readonly generateAutomationIcon?: (
        type: string,
        automation: string | null,
        iconColor: string,
        iconCircleColor?: string | null,
        fontSizeProp?: number | string | null,
        compactMode?: boolean,
        includePaymentPlan?: boolean
    ) => JSX.Element | undefined;
}
const TaskHistoryItemComponent: React.FC<IProps> = ({
    item,
    style,
    onRowClick,
    formatDateUTCToLocal,
    generateAutomationIcon,
}: IProps) => {
    const selectedId = useSelector(
        (state: ApplicationState) =>
            state.tasks.taskHistory.activeData.selectedId
    );

    const {
        BatchId: Id,
        CreatedDateTime,
        State,
        Type,
        BatchCount,
        Automation,
        Action
    } = item;

    const CreatedDateTimeLocal = formatDateUTCToLocal
        ? formatDateUTCToLocal(
              CreatedDateTime,
              null,
              dateFormatDDMMMYYYYHHmmSpace
          )
        : CreatedDateTime;

    /**
     * Function called when this row item is clicked.
     * Adds a key property to be used as an identifier for the selected item.
     */
    const handleRowClick = () => {
        const itemInfo: any = item;
        itemInfo.key = Id;
        if (onRowClick) onRowClick(itemInfo);
    };

    const assignedEmailAddress = get(item, 'AssignedEmailAddress');

    const fullName = get(item, 'User')
        ? getUserFullName(get(item, 'User'))
        : assignedEmailAddress;

    let iconColor = 'green';
    if (State === taskHistoryBatchStates.InProgress.value) {
        iconColor = 'blueCustom';
    } else if (State === taskHistoryBatchStates.Failed.value) {
        iconColor = 'red';
    }

    /**
     * Function that generates the automation icon for this row item.
     * Calls an HOC function to do so.
     */
    const generateItemIcon = () => {
        if (generateAutomationIcon) {
            const shouldUsePaymentPlan =
                Automation === taskHistoryAutomation.Manual;

            return generateAutomationIcon(
                Type,
                Automation,
                iconColor,
                undefined,
                undefined,
                undefined,
                shouldUsePaymentPlan
            );
        }
    };

    /**
     * Function for generating the message next to the icon inside this row item.
     * Depends on the row item data type and other properties.
     */
    const generateMessage = () => {
        const language = localStorage.getItem('language');
        const isEN = language && language.startsWith('en');
        if (Type === taskHistoryBatchTypes.Communication) {
            let actionWord = '';
            if (State === taskHistoryBatchStates.InProgress.value) {
                actionWord = 'Sending';
            } else {
                actionWord = 'Sent';
            }
            let automationWord = '';
            if (Automation === taskHistoryAutomation.Automatic) {
                automationWord = 'automated';
            } else if (Automation === taskHistoryAutomation.Manual) {
                automationWord = 'manual';
            }
            return `${getTranslatedText(actionWord)} ${BatchCount} ${getTranslatedText(automationWord)} ${getTranslatedText('notification')}${
                (BatchCount > 1 && isEN) ? 's' : ''
            }`;
        } else if (Type === taskHistoryBatchTypes.Ticket) {
            let actionWord = 'Completed';
            if (State === taskHistoryBatchStates.InProgress.value) {
                actionWord = 'Completing';
            }
            return `${getTranslatedText(actionWord)} ${getTranslatedText('an assistance/ticket task')}`;
        } else if (Type === taskHistoryBatchTypes.Changed) {
            let actionWord = 'Changed';
            if (State === taskHistoryBatchStates.InProgress.value) {
                actionWord = 'Changing';
            }
            return `${getTranslatedText(actionWord)} ${BatchCount} ${getTranslatedText('task')}${
                (BatchCount > 1 && isEN) ? 's' : ''
            }`;
        } else if (Type === taskHistoryBatchTypes.Collection) {
            let actionWord = 'Escalated';
            if (State === taskHistoryBatchStates.InProgress.value) {
                actionWord = 'Escalating';
            }
            return `${getTranslatedText(actionWord)} ${BatchCount} ${getTranslatedText('item')}${
                (BatchCount > 1 && isEN) ? 's' : ''
            } ${getTranslatedText('to collections')}`;
        } else if (Type === taskHistoryBatchTypes.PaymentPlan) {
            if (Object.values(paymentPlanApprovalTriggers).includes(Action)) {
                let actionWord;
                switch (Action) {
                    case paymentPlanApprovalTriggers.Approve:
                        actionWord = 'Approved';
                        break;
                    case paymentPlanApprovalTriggers.Reject:
                        actionWord = 'Rejected';
                        break;
                    default:
                        actionWord = 'Completed';
                        break;
                }
                return `${getTranslatedText(actionWord)} ${BatchCount} ${getTranslatedText('payment plan')}${(BatchCount > 1 && isEN) ? 's' : ''}`;
            } else {
                let actionWord = 'Completed';
                if (State === taskHistoryBatchStates.InProgress.value) {
                    actionWord = 'Completing';
                }
                return `${getTranslatedText(actionWord)} ${getTranslatedText('a payment plan task')}`;
            }
        } else if (Type === taskHistoryBatchTypes.PaymentAllocation) {
                if (State === taskHistoryBatchStates.InProgress.value) {
                    if(Action === 'Disregarded') 
                        return `${getTranslatedText('Disregarding')} ${BatchCount > 1 ? BatchCount: isEN?'a':''} ${getTranslatedText('payment allocation task')}${(BatchCount > 1 && isEN) ? 's': ''}`;
                    else if(Action === 'Unverified')
                        return `${getTranslatedText('Unverified')} ${BatchCount > 1 ? BatchCount: isEN?'a':''} ${getTranslatedText('payment allocation task')}${(BatchCount > 1 && isEN) ? 's': ''}`;
                    else
                        return `${getTranslatedText('Processing')} ${BatchCount > 1 ? BatchCount: isEN?'a':''} ${getTranslatedText('payment allocation task')}${(BatchCount > 1 && isEN) ? 's': ''}`;
                }
                else {
                    if(Action === 'Disregarded') 
                        return `${getTranslatedText('Disregarded')} ${BatchCount > 1 ? BatchCount: isEN?'a':''} ${getTranslatedText('payment allocation task')}${(BatchCount > 1 && isEN) ? 's': ''}`;
                    else if(Action === 'Unverified')
                        return `${getTranslatedText('Unverified')} ${BatchCount > 1 ? BatchCount: isEN?'a':''} ${getTranslatedText('payment allocation task')}${(BatchCount > 1 && isEN) ? 's': ''}`;
                    else
                        return `${getTranslatedText('Completed')} ${BatchCount > 1 ? BatchCount: isEN?'a':''} ${getTranslatedText('payment allocation task')}${(BatchCount > 1 && isEN) ? 's': ''}`;
                }
        } else if (Type === taskHistoryBatchTypes.PaymentVerification) {
            if (State === taskHistoryBatchStates.InProgress.value) {
                if(Action === 'Disregarded') 
                    return `${getTranslatedText('Disregarding')} ${BatchCount > 1 ? BatchCount: isEN?'a':''} ${getTranslatedText('payment verification task')}${(BatchCount > 1 && isEN) ? 's': ''}`;
                else
                    return `${getTranslatedText('Processing')} ${BatchCount > 1 ? BatchCount: isEN?'a':''} ${getTranslatedText('payment verification task')}${(BatchCount > 1 && isEN)? 's': ''}`;
            }
            else {
                if(Action === 'Disregarded') 
                    return `${getTranslatedText('Disregarded')} ${BatchCount > 1 ? BatchCount: isEN?'a':''} ${getTranslatedText('payment verification task')}${(BatchCount > 1 && isEN) ? 's': ''}`;
                else
                    return `${getTranslatedText('Completed')} ${BatchCount > 1 ? BatchCount: isEN?'a':''} ${getTranslatedText('payment verification task')}${(BatchCount > 1 && isEN) ? 's': ''}`;
            }
        } else if (Type === taskHistoryBatchTypes.RemittanceAdvice) {
            if (State === taskHistoryBatchStates.InProgress.value) {
                if (Action === "Disregarded") {
                    return `${getTranslatedText('Disregarding')} ${BatchCount > 1 ? BatchCount : isEN?'a':''} ${getTranslatedText('remittance advice task')}${(BatchCount > 1 && isEN) ? 's' : ''}`;
                }
                else {
                    return `${getTranslatedText('Processing')} ${BatchCount > 1 ? BatchCount : isEN?'a':''} ${getTranslatedText('remittance advice task')}${(BatchCount > 1 && isEN) ? 's' : ''}`;
                }
            }
            else {
                if (Action === "Disregarded") {
                    return `${getTranslatedText('Disregarded')} ${BatchCount > 1 ? BatchCount : isEN?'a':''} ${getTranslatedText('remittance advice task')}${(BatchCount > 1 && isEN) ? 's' : ''}`;
                }
                else {
                    return `${getTranslatedText('Completed')} ${BatchCount > 1 ? BatchCount: isEN?'a':''} ${getTranslatedText('remittance advice task')}${(BatchCount > 1 && isEN) ? 's': ''}`;
                }
            }
        } else if (Type === taskHistoryBatchTypes.PaymentBehaviourInsights) {
            return getTranslatedText('Actioned payment behaviour insights'); 
        } else if (Type === taskHistoryBatchTypes.PhoneCall) {
            if (State === taskHistoryBatchStates.InProgress.value) {
                return getTranslatedText('Processing a phone call task');
            }
            else {
                return getTranslatedText('Completed a phone call task');
            }
        }
    };

    /**
     * Function for generating the state of the task job.
     */
    const generateStateDisplay = () => {
        if (State === taskHistoryBatchStates.InProgress.value) {
            return 'In progress';
        } else {
            return State;
        }
    };

    return (
        <Row
            className={`list-item-clickable ${
                selectedId === Id ? 'list-item-clicked' : ''
            }`}
            key={Id}
            style={style}
            onClick={handleRowClick}
        >
            <Col
                span={24}
                style={{ height: '100%', paddingTop: 10, paddingBottom: 10 }}
            >
                <Row type="flex" align="middle" style={{ height: '100%' }}>
                    <Col span={2} className="coupled-icon-cont">
                        {generateItemIcon()}
                    </Col>
                    <Col
                        xl={10}
                        lg={9}
                        md={9}
                        sm={9}
                        xs={9}
                        style={{
                            paddingLeft: 14,
                            paddingRight: 14,
                        }}
                    >
                        {generateMessage()}
                    </Col>
                    <Col xl={7} lg={6} md={6} sm={6} xs={6}>
                        {fullName && <>{getTranslatedText('Actioned by')}: {fullName}</>}
                    </Col>
                    <Col xl={3} lg={5} md={5} sm={5} xs={5}>
                        <Row>
                            <Col span={24} className="ta-left">
                                {CreatedDateTimeLocal}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} className="ta-left">
                                <span>{getTranslatedText(generateStateDisplay())}</span>
                            </Col>
                        </Row>
                    </Col>
                    <Col
                        xl={2}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className="ta-center"
                    >
                        <AvatarNameInitialsComponent
                            fullName={fullName}
                            size="large"
                            isDefault={!isEmpty(assignedEmailAddress)}
                            showTooltip
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

// export default withAutomationIconsHandler(
//     withDateFormatHandler(withNumberFormatHandler(TaskHistoryItemComponent))
// );

const arePropsEqual = (prevProps: any, nextProps: any) => {
    if (
        JSON.stringify(prevProps.item) === JSON.stringify(nextProps.item) &&
        prevProps.selectedId === nextProps.selectedId &&
        JSON.stringify(prevProps.style) === JSON.stringify(nextProps.style)
    ) {
        return true;
    } else {
        return false;
    }
};

export default memo(
    withAutomationIconsHandler(
        withDateFormatHandler(withNumberFormatHandler(TaskHistoryItemComponent))
    ),
    arePropsEqual
);
