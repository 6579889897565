import moment from 'moment-timezone';
import { DynamicObject } from '../utils/commonInterfaces';
import { filter } from 'lodash';
import {
    dateSelectOptions,
    invoicesAmountOperatorOptions,
} from './invoicesSortAndFilters';

export const preConfiguredFilters = {
    ALL_PAYMENTS: 'All payments',
};

export const paymentsTableFilterOptions = [
    // { label: 'All open invoices', value: 'Open' },
    { label: 'All payments', value: preConfiguredFilters.ALL_PAYMENTS },
];

export const paymentsSortByOptions = [
    { label: 'Company name', value: 'Company name' },
    { label: 'Customer code', value: 'Customer code' },
    { label: 'Payment date', value: 'Paid date' },
    { label: 'Amount paid', value: 'Paid amount' },
    { label: 'Result', value: 'Result' },
];

export const paymentsCommonSortByOptions = [
    { label: 'Result', value: 'Result' },
    { label: 'Payment date', value: 'Paid date' },
    { label: 'Amount paid', value: 'Paid amount' },
];

export const unallocatedAmountType = 'Unallocated';

export const paymentsAmountType = [
    { label: 'Paid', value: 'Paid' },
    { label: 'Unallocated', value: 'Unallocated'}
    // { label: 'Received', value: 'Received' },
];

const {
    THIS_MONTH,
    LAST_MONTH,
    LAST_7_DAYS,
    LAST_30_DAYS,
    CUSTOM_DATE_RANGE,
    CUSTOM_DAYS_RANGE,
} = dateSelectOptions;

export const paymentsPaidDateOptions = [
    THIS_MONTH,
    LAST_MONTH,
    LAST_7_DAYS,
    LAST_30_DAYS,
    CUSTOM_DATE_RANGE,
    CUSTOM_DAYS_RANGE,
];

export const paymentsSettledDateOptions = [
    THIS_MONTH,
    LAST_MONTH,
    LAST_7_DAYS,
    LAST_30_DAYS,
    CUSTOM_DATE_RANGE,
    CUSTOM_DAYS_RANGE,
];

export const paymentResults = {
    SUCCESSFUL: 'Successful',
    UNCONFIRMED: 'Unconfirmed',
    FAILED: 'Failed',
    DISREGARDED: 'Disregarded'
};

export const paymentStateFilterOptions = [
    {label: 'All active payments', value: 'Active'},
    {label: 'All payments', value: 'All'}
];

export const paymentResultOptions = [
    {
        label: paymentResults.SUCCESSFUL,
        value: 1,
    },
    {
        label: paymentResults.FAILED,
        value: 2,
    },
    {
        label: paymentResults.UNCONFIRMED,
        value: 4,
    },
    {
        label: paymentResults.DISREGARDED,
        value: 8
    }
];

export const getPaymentsFilterBarFilters = (supportCashAllocation: Boolean, customerValuesOption: any, customerLabel: string) => {
    let amountTypes = filter(
        paymentsAmountType,
        (pat: DynamicObject) => (supportCashAllocation || unallocatedAmountType != pat.label)
    );

    return [
        {
            filterName: 'Customer',
            filterStateName: 'Customer',
            filterElement: 'input-autocomplete',
            filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
            filterSort: 'Company name',
            filterResponse: 'GetCustomersForCompany.Customers',
            filterLabelField: 'DisplayName',
        },
        {
            filterName: customerLabel,
            filterStateName: 'CustomerFirstLetter',
            filterElement: 'checkbox-group',
            filterType: 'array',
            filterOptions: customerValuesOption
        },
        {
            filterName: 'Result',
            filterStateName: 'Result',
            filterElement: 'checkbox-group',
            filterType: 'array',
            filterOptions: paymentResultOptions,
        },
        {
            filterName: 'Paid in',
            filterStateName: 'PaidDate',
            filterElement: 'select',
            filterType: 'text',
            filterMaxDate: moment(),
            filterOptions: paymentsPaidDateOptions,
            filterOptionsCustomTypes: {
                'Custom date range': 'date-range',
                'Custom days range': 'all-days-range-from-to-last',
            }
        },
        {
            filterName: 'Amount',
            filterStateName: 'Amount',
            filterElement: 'select-multiple-and-input-amount',
            filterType: 'text',
            filterOptions: [
                {
                    AmountType: amountTypes,
                },
                {
                    AmountOperator: invoicesAmountOperatorOptions,
                },
            ],
        }
    ];
};

export const paymentsStateFilterOptions = [
    { label: 'Open payments', value: 'Open' },
    { label: 'All payments', value: 'All' },
];

/**
 * For payment changes
 */

export const paymentChangesFilters = {
    ALL_CHANGES: 'All changes',
};

export const paymentChangesTableFilterOptions = [
    {
        label: 'All conversations',
        value: paymentChangesFilters.ALL_CHANGES,
    },
];

export const paymentChangeTypes = {
    NewResource: 'NewResource',
    UpdatedResource: 'UpdatedResource',
    Allocation: 'Allocation',
    Deallocation: 'Deallocation',
    Settlement: 'Settlement',
    ResourceLink: 'ResourceLink',
    Disregarded: 'Disregarded',
    Verified: 'Verified',
    Unverified: 'Unverified'
};

export const paymentTypes = {
    LINK: 'PaymentURLClick',
    WESTERN_UNION: 'WesternUnion',
    CORPAY: 'Corpay',
    CHEQUE: 'Cheque',
    EZIDEBIT: 'EziDebit',
    INTEGRAPAY: 'IntegraPay',
    ADVAM: 'Advam',
};

export const paymentMethodTypes = {
    CARD: 'Card',
    BANK_DEPOSIT: 'BankDeposit',
};

export const paymentsWidgetFilters = (supportCashAllocation: Boolean) => {
    let amountTypes = filter(
        paymentsAmountType,
        (pat: DynamicObject) => (supportCashAllocation || unallocatedAmountType !== pat.label)
    );
    
    return [
        {
            filterName: 'Customer',
            filterStateName: 'Customer',
            filterElement: 'input-autocomplete',
            filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
            filterSort: 'Company name',
            filterResponse: 'GetCustomersForCompany.Customers',
            filterLabelField: 'DisplayName',
        },
        {
            filterName: 'Result',
            filterStateName: 'Result',
            filterElement: 'checkbox-group',
            filterType: 'array',
            filterOptions: paymentResultOptions,
            filterPlaceholder: 'Select Result',
            filterExcluded: true,
        },
        {
            filterName: 'Paid in',
            filterStateName: 'PaidDate',
            filterElement: 'select',
            filterType: 'text',
            filterMaxDate: moment(),
            filterOptions: paymentsPaidDateOptions,
            filterOptionsCustomTypes: {
                'Custom date range': 'date-range',
                'Custom days range': 'all-days-range-from-to-last',
            },
            filterPlaceholder: 'Select Date Range',
        },
        {
            filterName: 'Amount',
            filterStateName: 'Amount',
            filterElement: 'select-multiple-and-input-amount',
            filterType: 'text',
            filterOptions: [
                {
                    AmountType: amountTypes,
                    filterOptionName: 'AmountType',
                    filterPlaceholder: 'Select Amount Type'
                },
                {
                    AmountOperator: invoicesAmountOperatorOptions,
                    filterOptionName: 'AmountOperator',
                    filterPlaceholder: 'Select Operator',
                },
            ],
        },
    ];
};