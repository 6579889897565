/**
 * Component to populate each row items for the Active tasks table.
 */

import { Button, Checkbox, Col, Collapse, Row, Tooltip } from 'antd';
import {
    capitalize,
    compact,
    concat,
    get,
    includes,
    isEmpty,
    isEqual,
    isUndefined,
    lowerCase,
    map,
    toLower,
} from 'lodash';
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNumberOfDaysBeforeRecentCommentTurnedOff } from '../../../store/companies/sagas';
import {
    actionDateMovedByTypes,
    ContactFilterOptions,
    taskHistoryAutomation,
    taskHistoryBatchTypes,
    taskItemColorsAndLabel,
} from '../../../constants/tasksSortAndFilters';
import '../../../pages/sales/sales.less';
import { ApplicationState } from '../../../store';
import { CustomField } from '../../../store/common/types';
import { setTaskExpandStatusAction } from '../../../store/tasks/actions';
import { Task } from '../../../store/tasks/types';
import {
    checkIfTaskReady,
    getCompanyName,
    getIfIsLg,
    getIndefiniteArticleBySucceedingWordOrPhrase,
    getTranslatedText
} from '../../../utils/commonFunctions';
import { DynamicObject } from '../../../utils/commonInterfaces';
import { withAutomationIconsHandler } from '../../common/AutomationIconsHandler';
import AvatarNameInitialsComponent from '../../common/AvatarNameInitialsComponent';
import ContactInformationIcons from '../../common/ContactInformationIcons';
import { withDateFormatHandler } from '../../common/DateFormatHandler';
import FontAwesome from '../../common/FontAwesome';
import { withNumberFormatHandler } from '../../common/NumberFormatHandler';
import { generateCommentItemWithType } from '../../common/SharedExportComponents';


interface IProps {
    readonly item: Task;
    readonly style: {};
    readonly onRowClick: (item: DynamicObject) => void;
    readonly onCheckboxClick: (item: DynamicObject) => void;
    readonly selectedRowKeys: [];
    readonly formatCurrency?: (amount: number) => JSX.Element;
    readonly formatDateUTCToLocal?: (
        date: any,
        fromFormat?: string | null,
        toFormat?: string | null
    ) => string;
    readonly generateAutomationIcon?: (
        type: string,
        automation: string | null,
        iconColor: string,
        iconCircleColor?: string | null,
        fontSizeProp?: number | string | null,
        compactMode?: boolean,
        includePaymentPlan?: boolean
    ) => JSX.Element | undefined;
    readonly usingCustomerWorkflow?: boolean;
    readonly lockedDeliveryMethod?: boolean;
    readonly selectedId?: string;
    readonly isExpanded?: boolean;
    readonly customerLabel?: string;
    readonly shouldDisableSelect?: (item: Task) => boolean;
    readonly isAppliedRecordLimit: boolean;
}
const TaskItemComponent: React.FC<IProps> = ({
    item,
    style,
    onRowClick,
    onCheckboxClick,
    selectedRowKeys,
    formatDateUTCToLocal,
    formatCurrency,
    generateAutomationIcon,
    usingCustomerWorkflow,
    lockedDeliveryMethod,
    selectedId,
    isExpanded,
    customerLabel,
    shouldDisableSelect,
}: IProps) => {
    
    const dispatch = useDispatch();
    const menuCollapsed: boolean = useSelector(
        (state: ApplicationState) => state.storedRoute.menuCollapsed
    );
    const numberOfDaysBeforeRecentCommentTurnedOff: number = useSelector(getNumberOfDaysBeforeRecentCommentTurnedOff);

    const {
        Id,
        ActionDate,
        Automation,
        Customer,
        Invoice,
        Type,
        Workflow,
        State,
        Ticket,
        ConversationLine,
        Payment,
        RemittanceAdvice,
        PaymentPlanSchedule,
        PaymentPlan,
        ActionDateMovedBy,
        CommonInvoiceCustomFields,
        Company
    } = item;

    const Contacts = get(Customer, 'Contacts');
    const PaymentDetails = get(Payment, 'PaidAmount');
    const CustomerCode = get(Customer, 'CustomerCode');
    const InvoiceNumber = get(Invoice, 'Number', '');
    const AmountOwing = get(Invoice, 'AmountOwing');
    const StateName = get(Workflow, 'StateName', '');
    const CompanyName = get(Company, 'Name');
    const WorkflowDeliveryMethod = get(Workflow, 'DeliveryMethod', 0);
    const AvailableContactMethods = lockedDeliveryMethod
        ? WorkflowDeliveryMethod
        : undefined;
        
    const ActionDateLocal = formatDateUTCToLocal
        ? formatDateUTCToLocal(ActionDate)
        : ActionDate;

    const isTaskReady = checkIfTaskReady(State, ActionDate);

    /**
     * Function called when checkbox is clicked.
     * @param e
     */
    const handleCheckboxClick = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        e.stopPropagation();
        const itemInfo: any = item;
        itemInfo.key = Id;
        itemInfo.isReady = isTaskReady;
        if (onCheckboxClick) onCheckboxClick(itemInfo);
    };

    /**
     * Function called when the row item is clicked.
     */
    const handleRowClick = () => {
        const itemInfo: any = item;
        itemInfo.key = Id;
        if (onRowClick) onRowClick(itemInfo);
    };

    const givenName = get(item, 'AssignedUser.GivenName');
    const familyName = get(item, 'AssignedUser.FamilyName');
    const assignedEmail = get(item, 'AssignedEmailAddress');
    let name = '';
    if (givenName && familyName) {
        name = `${givenName} ${familyName}`;
    } else if (assignedEmail) {
        name = assignedEmail;
    }

    /**
     * Function that converts a number into currency format if HOC function `formatCurrency` is available.
     * @param amount - number to format
     */
    const handleFormatCurrency = (amount: number) => {
        return formatCurrency ? formatCurrency(amount) : 0.00;
    };

    const companyNameCorrect = getCompanyName(Customer);

    /**
     * Function that gets the ticket option reason if present.
     */
    const populateTicketOptionTypeLabel = () => {
        return get(Ticket, 'TicketOption.Reason', '');
    };

    /**
     * Function to get the description needed based on task type and other properties.
     */
    const populateTaskDescription = () => {
        const indefiniteArticle =
            getIndefiniteArticleBySucceedingWordOrPhrase(StateName);
        let description;
        if (
            (Type === taskHistoryBatchTypes.Communication ||
                Type === taskHistoryBatchTypes.PaymentSchedule) &&
            Automation === taskHistoryAutomation.Automatic
        ) {
            if (Type === taskHistoryBatchTypes.PaymentSchedule) {
                description = (
                    <div>
                        Send {indefiniteArticle} {lowerCase(StateName)} for{' '}
                        {handleFormatCurrency(
                            get(PaymentPlanSchedule, 'AmountRemaining', 0)
                        )}{' '}
                        to {companyNameCorrect}
                    </div>
                );
            } else if (usingCustomerWorkflow) {
                description = (
                    <div>
                        Send {indefiniteArticle} {lowerCase(StateName)} to{' '}
                        {companyNameCorrect}
                    </div>
                );
            } else {
                description = (
                    <div style={{ whiteSpace: 'pre' }}>
                        Send {indefiniteArticle} {lowerCase(StateName)} of{' '}
                        {handleFormatCurrency(AmountOwing)} to{' '}
                        {companyNameCorrect} for invoice {InvoiceNumber}
                    </div>
                );
            }
        } else if (
            Type === taskHistoryBatchTypes.PaymentSchedule &&
            Automation === taskHistoryAutomation.Manual
        ) {
            description = (
                <div>
                    {companyNameCorrect} has missed payments for plan #
                    {get(PaymentPlan, 'Number', '--')} and the plan should be
                    cancelled
                </div>
            );
        } else if (Type === taskHistoryBatchTypes.Ticket) {
            description = <div>{populateTicketOptionTypeLabel()}</div>;
        } else if (Type === taskHistoryBatchTypes.Collection) {
            if (usingCustomerWorkflow) {
                description = (
                    <div>
                        Notify the collections team about{' '}
                        {toLower(customerLabel)} {companyNameCorrect}.
                    </div>
                );
            } else {
                description = (
                    <div style={{ whiteSpace: 'pre' }}>
                        Notify the collections team about invoice{' '}
                        {InvoiceNumber} which has{' '}
                        {handleFormatCurrency(AmountOwing)} outstanding.
                    </div>
                );
            }
        } else if (Type === taskHistoryBatchTypes.NewInvoiceEmail) {
            if (AmountOwing) {
                description = (
                    <div style={{ whiteSpace: 'pre' }}>
                        Send {indefiniteArticle} {lowerCase(StateName)} of{' '}
                        {handleFormatCurrency(AmountOwing)} to{' '}
                        {companyNameCorrect} for invoice {InvoiceNumber}
                    </div>
                );
            } else {
                description = (
                    <div style={{ whiteSpace: 'pre' }}>
                        Notify {companyNameCorrect} of {lowerCase(StateName)} {InvoiceNumber}
                    </div>
                );
            }
        } else if (Type === taskHistoryBatchTypes.PaymentPlan) {
            const TotalAmount = get(PaymentPlan, 'TotalAmount');
            const Number = get(PaymentPlan, 'Number');
            description = (
                <div>
                    Approve or reject the payment plan #{Number} for {companyNameCorrect}
                </div>
            );
        } else if (Type === taskHistoryBatchTypes.PaymentManualAllocation) {
            const PaidAmount = get(Payment, 'PaidAmount') - get(Payment,'AllocatedAmount');
            const PaidDate = formatDateUTCToLocal ? formatDateUTCToLocal(get(Payment, 'PaidDate')) : get(Payment, 'PaidDate');
     
            description = (
                <div>
                    {/* Allocate payment of {handleFormatCurrency(PaidAmount)} received on {PaidDate} */}
                    {getTranslatedText("Allocate payment of [amount] received on [date]")
                    .replace("[amount]", handleFormatCurrency(PaidAmount).toString())
                    .replace("[date]", PaidDate)}

                </div>
            );
        }
        else if (Type === taskHistoryBatchTypes.PaymentVerification) {
            const PaidAmount = get(Payment, 'PaidAmount') - get(Payment,'AllocatedAmount');
            const PaidDate = formatDateUTCToLocal ? formatDateUTCToLocal(get(Payment, 'PaidDate')) : get(Payment, 'PaidDate');
     
            description = (
                <div>
                    {/* Complete payment of {handleFormatCurrency(PaidAmount)} on {PaidDate} */}
                    {getTranslatedText("Complete payment of [amount] received on [date]")
                    .replace("[amount]", handleFormatCurrency(PaidAmount).toString())
                    .replace("[date]", PaidDate)}
                </div>
            );
        }
        else if (Type === taskHistoryBatchTypes.RemittanceAdvice) {
            const RemittanceAdviceAmount = get(RemittanceAdvice, 'Amount') - get(RemittanceAdvice,'AllocatedAmount');
            const ReceivedDate = get(RemittanceAdvice, 'ReceivedDate');
            const ReceivedDateDisplay = (ReceivedDate && formatDateUTCToLocal) ? formatDateUTCToLocal(ReceivedDate) : ReceivedDate;

            description = (
                <div>
                    {getTranslatedText("Confirm remittance advice")}
                    {
                        RemittanceAdviceAmount
                            ? ` of ${handleFormatCurrency(RemittanceAdviceAmount)}`
                            : ''
                    }
                    {
                        ReceivedDateDisplay
                            ? ` received on ${ReceivedDateDisplay}`
                            : ''
                    }
                </div>
            );
        } else if (Type === taskHistoryBatchTypes.PaymentBehaviourInsights) {
            description = (
                <div>
                    Mark {companyNameCorrect} as { get(Customer, 'PayerState') }
                </div>
            );
        } else if (Type === taskHistoryBatchTypes.CreditLimit) {
            description = (
                <div>
                    Notify {companyNameCorrect} about the current credit limit status
                </div>
            );
        } else if (Type === taskHistoryBatchTypes.PhoneCall) {
            if (usingCustomerWorkflow) {
                description = (
                    <div>
                        Call {companyNameCorrect}
                    </div>
                );
            } else {
                description = (
                    <div style={{ whiteSpace: 'pre' }}>
                        Call {companyNameCorrect} for the invoice {InvoiceNumber} of {handleFormatCurrency(AmountOwing)}
                    </div>
                );
            }
        }
        
        return description;
    };

    const { itemColor, readyLabel, iconCircleColor, iconContentColor } =
        taskItemColorsAndLabel(Type, StateName, isTaskReady, ActionDate);

    const description = populateTaskDescription();

    /**
     * Function that calls an HOC function to generate the automation icon appropriate for this item.
     */
    const generateItemIcon = (compactView?: boolean) => {
        if (generateAutomationIcon) {
            const shouldUsePaymentPlan =
                Automation === taskHistoryAutomation.Manual;
                
            return generateAutomationIcon(
                Type,
                Automation,
                iconContentColor,
                iconCircleColor,
                24,
                compactView,
                shouldUsePaymentPlan
            );
        }
    };

    const AllCustomFields = usingCustomerWorkflow
        ? compact(get(Customer, 'CustomFields'))
        : concat(
              compact(get(Invoice, 'CustomFields')),
              compact(CommonInvoiceCustomFields || []),
              compact(get(Customer, 'CustomFields'))
          );

    /**
     * Function that populates the custom fields section
     */
    const populateCustomFields = (taskType: string) => {
        const customFieldsDivElements = map(
            AllCustomFields,
            ({ Name, Value }: CustomField) => (
                <Col key={`${Name}-${Value}`}>
                    <span className="mr-8">{Name}:</span>
                    <span>{Value}</span>
                </Col>
            )
        );
        return (
            <Row className="center-flex-i fw-w" gutter={30}>
                {customFieldsDivElements}
            </Row>
        );
    };

    /**
     * Function for populating remittance advice count
     */
    const populateRemittanceAdviceCount = () => {
        const paymentCustomer = get(Payment, 'Customer') || '';
        const remittanceAdviceCounts = get(paymentCustomer, 'RemittanceAdviceCounts') || '';

        const remittanceAdviceCountContent = (
           <>
            <div>
                <Row>
                    <Col span={24}>
                        <span>{getTranslatedText("[count] Verified remittance[s] available").replace("[s]", parseInt(remittanceAdviceCounts.VerifiedRAs) != 1 ? 's' : '').replace("[count]", remittanceAdviceCounts.VerifiedRAs)}</span>
                    </Col>
                    <Col span={24}>
                        <span>{getTranslatedText("[count] Remittance[s] requiring confirmation").replace("[s]", parseInt(remittanceAdviceCounts.UnprocessedRAs) != 1 ? 's' : '').replace("[count]", remittanceAdviceCounts.UnprocessedRAs)} </span>
                    </Col>
                </Row>
            </div>
           </>
        );

        return (
            <Col className="pa-0">
                {(remittanceAdviceCounts.VerifiedRAs > 0 || remittanceAdviceCounts.UnprocessedRAs > 0) && <Tooltip
                title={remittanceAdviceCountContent}
                >
                    <div className="fa-layers fa-fw fa fs-20">
                <FontAwesome
                    className='blue'
                    icon={['far', 'comment-alt']}
                    transform="grow-2 down-2"
                />
                <FontAwesome
                    className='blue'
                    icon={['fas', 'search']}
                    transform="shrink-7"
                />
            </div>
                </Tooltip>}
            </Col>
        );
    };

    /**
     * Common function for populating comments section
     */
    const populateCommentsSection = (
        full: boolean,
        withInvoice?: boolean,
        noWidth?: boolean
    ) => {
        const customerComment = get(Customer, 'ConversationLine');
        const invoiceComment = withInvoice
            ? get(Invoice, 'ConversationLine')
            : null;
        const taskComment = ConversationLine;
        const ticketComment = get(Ticket, 'ConversationLine');
        const ticketChat = get(Ticket, 'ConversationChatLine');
        const paymentPlanComment = get(PaymentPlan, 'ConversationLine');
        const paymentComment = get(Payment, 'ConversationLine');
        const remittanceAdviceComment = get(RemittanceAdvice, 'ConversationLine');

        const widthSpans = noWidth
            ? {}
            : {
                  xl: full ? 24 : 0,
                  lg: full ? 0 : 24,
                  md: full ? 0 : 24,
                  sm: full ? 0 : 24,
                  xs: full ? 0 : 24,
              };
        return (
            (customerComment ||
                invoiceComment ||
                taskComment ||
                ticketChat ||
                ticketComment ||
                paymentPlanComment || 
                paymentComment ||
                remittanceAdviceComment) && (
                <Col {...widthSpans} className="pa-0">
                    {ticketChat &&
                        generateCommentItemWithType(ticketChat, 'chat', numberOfDaysBeforeRecentCommentTurnedOff, undefined, undefined, undefined, customerLabel)}
                    {invoiceComment &&
                        generateCommentItemWithType(invoiceComment, 'invoice', numberOfDaysBeforeRecentCommentTurnedOff)}
                    {ticketComment &&
                        generateCommentItemWithType(ticketComment, 'ticket', numberOfDaysBeforeRecentCommentTurnedOff)}
                    {paymentPlanComment &&
                        generateCommentItemWithType(
                            paymentPlanComment,
                            'paymentPlan',
                            numberOfDaysBeforeRecentCommentTurnedOff
                        )}
                    {customerComment &&
                        generateCommentItemWithType(
                            customerComment,
                            'customer',
                            numberOfDaysBeforeRecentCommentTurnedOff
                        )}
                    {remittanceAdviceComment &&
                        generateCommentItemWithType(
                            remittanceAdviceComment,
                            'remittanceAdvice',
                            numberOfDaysBeforeRecentCommentTurnedOff
                        )}
                    {taskComment &&
                        generateCommentItemWithType(taskComment, 'task', numberOfDaysBeforeRecentCommentTurnedOff)}
                    {paymentComment &&
                        generateCommentItemWithType(
                            paymentComment,
                            'payment',
                            numberOfDaysBeforeRecentCommentTurnedOff
                        )
                    }
                </Col>
            )
        );
    };

    /**
     * Function to populate the row item content based on task type and other properties.
     */
    const generateRowItemContent = () => {
        const isLg = getIfIsLg();

        const assignedToUserSection = name && (
            <Row>
                <Col>{getTranslatedText('Assigned to')}: {name}</Col>
            </Row>
        );

        const setTaskExpandStatus = (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(setTaskExpandStatusAction(Id));
        };

        const populateAvatar = (
            size: number = 45,
            compactRender: boolean = false
        ) => {
            return (
                <AvatarNameInitialsComponent
                    fullName={name}
                    size={size}
                    showTooltip
                    isDefault={!isEmpty(assignedEmail)}
                    fontSize={compactRender ? 12 : 18}
                />
            );
        };

        let mainRowProps: DynamicObject = {
            className: `center-flex-i h-100 ${isLg ? 'fw-w' : ''}`,
        };
        let checkboxContainerStyle: DynamicObject = {
            height: 'auto',
        };
        let iconContainerStyle: DynamicObject = {
            paddingRight: isLg ? 0 : 5,
        };
        let isNewInvoice = false;
        let isCommunication = false;
        let isCollection = false;
        let isPhoneCall = false;
        let contactIcons: any = undefined;
        let commentsSection: any = undefined;
        let customFieldsSection: any = undefined;

        if (Type === taskHistoryBatchTypes.Ticket) {
            commentsSection = populateCommentsSection(true, false, true);
            customFieldsSection = populateCustomFields(
                taskHistoryBatchTypes.Ticket
            );
        } else if (Type === taskHistoryBatchTypes.Communication) {
            isCommunication = true;
            contactIcons = (
                <ContactInformationIcons
                    contactInformation={get(Contacts, 0)}
                    availableContactMethods={AvailableContactMethods}
                    placement="right"
                    iconTransform="grow-2"
                />
            );
            commentsSection = populateCommentsSection(true, true, true);
            customFieldsSection = populateCustomFields(
                taskHistoryBatchTypes.Communication
            );
        } else if (Type === taskHistoryBatchTypes.PaymentVerification) {
            commentsSection = populateCommentsSection(false, false, true);
            customFieldsSection = populateCustomFields(
                taskHistoryBatchTypes.PaymentVerification
            );
        } else if (Type === taskHistoryBatchTypes.RemittanceAdvice) {
            commentsSection = populateCommentsSection(false, false, true);
            customFieldsSection = populateCustomFields(
                taskHistoryBatchTypes.RemittanceAdvice
            );
        } else if (Type === taskHistoryBatchTypes.PaymentSchedule) {
            if (Automation === taskHistoryAutomation.Automatic) {
                isCommunication = true;
                contactIcons = (
                    <ContactInformationIcons
                        contactInformation={get(Contacts, 0)}
                        availableContactMethods={AvailableContactMethods}
                        placement="right"
                        iconTransform="grow-2"
                    />
                );
            }

            commentsSection = populateCommentsSection(true, false, true);
            customFieldsSection = populateCustomFields(
                taskHistoryBatchTypes.PaymentSchedule
            );
        } else if (Type === taskHistoryBatchTypes.Collection) {
            isCollection = true;
            customFieldsSection = populateCustomFields(
                taskHistoryBatchTypes.Collection
            );
            commentsSection = populateCommentsSection(true, true, true);
        } else if (Type === taskHistoryBatchTypes.NewInvoiceEmail) {
            isCommunication = true;
            isNewInvoice = true;
            contactIcons = (
                <ContactInformationIcons
                    contactInformation={get(Contacts, 0)}
                    availableContactMethods={WorkflowDeliveryMethod}
                    placement="right"
                    iconTransform="grow-2"
                />
            );
            commentsSection = populateCommentsSection(true, true, true);
            customFieldsSection = populateCustomFields(
                taskHistoryBatchTypes.NewInvoiceEmail
            );
        } else if (Type === taskHistoryBatchTypes.PaymentPlan) {
            isCommunication = false;
            isNewInvoice = false;
            commentsSection = populateCommentsSection(true, false, true);
            customFieldsSection = populateCustomFields(
                taskHistoryBatchTypes.PaymentPlan
            );
        } else if (Type === taskHistoryBatchTypes.PaymentManualAllocation) {
            commentsSection = populateCommentsSection(false, false, true);
            customFieldsSection = populateCustomFields(
                taskHistoryBatchTypes.PaymentVerification
            );
        } else if (Type === taskHistoryBatchTypes.PaymentBehaviourInsights) {
            commentsSection = populateCommentsSection(false, false, true);
            customFieldsSection = populateCustomFields(
                taskHistoryBatchTypes.PaymentBehaviourInsights
            );
        } else if (Type === taskHistoryBatchTypes.CreditLimit) {
            isCommunication = true;
            contactIcons = (
                <ContactInformationIcons
                    contactInformation={get(Contacts, 0)}
                    availableContactMethods={WorkflowDeliveryMethod}
                    placement="right"
                    iconTransform="grow-2"
                />
            );
            commentsSection = populateCommentsSection(false, false, true);
            customFieldsSection = populateCustomFields(
                taskHistoryBatchTypes.CreditLimit
            );
        } else if (Type === taskHistoryBatchTypes.PhoneCall) {
            isPhoneCall = true;
            commentsSection = populateCommentsSection(false, true, true);
            customFieldsSection = populateCustomFields(
                taskHistoryBatchTypes.PhoneCall
            );
        }

        const readySection = (
            <>  
                {(ActionDateMovedBy === actionDateMovedByTypes.PromiseToPay) && 
                (<FontAwesome
                    icon={['fas', 'handshake']}
                    size={'1x'}
                    className={'green'}
                />)}
                {' '}
                <b className={itemColor}>
                    {getTranslatedText(readyLabel)} {ActionDateLocal}
                </b>
            </>
        );

        const populateMidTextCompactHeader = () => {
            let firstText =
                !usingCustomerWorkflow && !isEmpty(Invoice) && Type !== taskHistoryBatchTypes.PaymentVerification
                    ? InvoiceNumber
                    : '';
            let tooltipTitle = 'Invoice number';
            let secondText = `Send ${lowerCase(StateName)}`;
            if (Type === taskHistoryBatchTypes.Ticket) {
                const ticketNumber = get(Ticket, 'TicketNumber');
                tooltipTitle = 'Ticket number';
                firstText = ticketNumber ? `Ticket #${ticketNumber}` : '';
                secondText = StateName;
            } else if (Type === taskHistoryBatchTypes.Collection) {
                secondText = capitalize(lowerCase(StateName));
            } else if (Type === taskHistoryBatchTypes.PaymentSchedule) {
                if (Automation === taskHistoryAutomation.Automatic) {
                    const paymentSchedule = get(PaymentPlanSchedule, 'Number');
                    tooltipTitle = 'Payment instalment';
                    firstText = paymentSchedule
                        ? `Schedule #${paymentSchedule}`
                        : '';
                } else {
                    secondText = capitalize(StateName);
                }
            } else if (Type === taskHistoryBatchTypes.PaymentPlan) {
                const Number = get(PaymentPlan, 'Number');
                firstText = `Payment plan #${Number}`;
                secondText = StateName;
            } else if (Type === taskHistoryBatchTypes.PaymentVerification || Type === taskHistoryBatchTypes.PaymentManualAllocation) {
                secondText = getTranslatedText(StateName);
            } else if (Type === taskHistoryBatchTypes.RemittanceAdvice) {
                secondText = getTranslatedText('Confirm remittance advice');
            } else if (Type === taskHistoryBatchTypes.PaymentBehaviourInsights) {
                secondText = get(Workflow, 'StateName');
            } else if (Type === taskHistoryBatchTypes.PhoneCall) {
                secondText = get(Workflow, 'StateName');
            }

            return (
                <Row type="flex">
                    <Col
                        className="wb-bw"
                        style={{
                            width: isLg ? '100%' : 150,
                            paddingLeft: isLg ? 0 : 10,
                        }}
                    >
                        <Tooltip title={getTranslatedText(tooltipTitle)} placement="topRight">
                            <span style={{ whiteSpace: 'pre' }}>{firstText}</span>
                        </Tooltip>
                    </Col>
                    <Col
                        className="fx-1 wb-bw"
                        style={{
                            fontWeight:
                                readyLabel === 'Ready since'
                                    ? 'bold'
                                    : 'normal',
                        }}
                    >
                        {secondText}
                    </Col>
                </Row>
            );
        };

        const populateCompactViewListItem = () => {
            return (
                <Row
                    gutter={{ xxl: 20, xl: 20, lg: 20 }}
                    type="flex"
                    align="middle"
                >
                    <Col
                        xxl={2}
                        xl={3}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className="h-100"
                        style={{ paddingLeft: 5, paddingRight: 3 }}
                    >
                        <Row {...mainRowProps}>
                            <Col
                                xl={10}
                                lg={24}
                                md={24}
                                sm={24}
                                xs={24}
                                style={{
                                    paddingBottom: isLg ? 4 : 0,
                                }}
                            >
                                <div
                                    className="center-flex sb-flex"
                                    style={{
                                        height: '1.2em',
                                    }}
                                >
                                    <div
                                        className="ta-center pl-12"
                                        style={{
                                            ...checkboxContainerStyle,
                                            width: '2em',
                                        }}
                                    >
                                        <Checkbox
                                            disabled={shouldDisableSelect && shouldDisableSelect(item)}
                                            checked={includes(
                                                selectedRowKeys,
                                                Id
                                            )}
                                            onClick={handleCheckboxClick}
                                        />
                                    </div>
                                    <div>
                                        <Button
                                            type="link"
                                            onClick={setTaskExpandStatus}
                                            style={{
                                                zIndex: 20,
                                                paddingLeft: 5,
                                                marginLeft: 10,
                                            }}
                                        >
                                            <FontAwesome
                                                icon={[
                                                    'fas',
                                                    isExpanded
                                                        ? 'angle-up'
                                                        : 'angle-down',
                                                ]}
                                            />
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                            {!isExpanded && (
                                <Col
                                    xl={14}
                                    lg={24}
                                    md={24}
                                    sm={24}
                                    xs={24}
                                    className="ta-center"
                                    style={{
                                        ...iconContainerStyle,
                                        marginLeft: isLg
                                            ? menuCollapsed
                                                ? -10
                                                : -5
                                            : 0,
                                    }}
                                >
                                    <div
                                        className="coupled-icon-cont ta-center center-flex-all"
                                        style={{
                                            minHeight: isLg ? 24 : 44,
                                        }}
                                    >
                                        {generateItemIcon(true)}
                                    </div>
                                </Col>
                            )}
                        </Row>
                    </Col>
                    <Col xxl={22} xl={21} lg={22} md={22} sm={22} xs={22}>
                        <Row type="flex" align="middle">
                            <Col className="fx-1">
                                <Row
                                    className="first-row-container"
                                    type="flex"
                                    gutter={10}
                                >
                                    <Col span={24} className="pl-10">
                                        <div>
                                            <Row
                                                type="flex"
                                                align="middle"
                                                gutter={10}
                                            >
                                                <Col className="fx-1">
                                                    <Row
                                                        type="flex"
                                                        // gutter={itemGutter}
                                                    >
                                                        <Col className="fx-1">
                                                            <Row type="flex">
                                                                {!isUndefined(
                                                                    CustomerCode
                                                                ) && (
                                                                    <Col
                                                                        className="wb-bw"
                                                                        style={{
                                                                            width: isLg
                                                                                ? '100%'
                                                                                : 150, //CustomerCode width
                                                                        }}
                                                                    >
                                                                        <Tooltip
                                                                            title={getTranslatedText(`${capitalize(customerLabel)} code`)}
                                                                            placement="topRight"
                                                                        >
                                                                            <span>
                                                                                {
                                                                                    CustomerCode
                                                                                }
                                                                            </span>
                                                                        </Tooltip>
                                                                    </Col>
                                                                )}
                                                                <Col className="fx-1">
                                                                    <Tooltip
                                                                        title={getTranslatedText(`${capitalize(customerLabel)} name`) }
                                                                        placement="topRight"
                                                                    >
                                                                        <b className="wb-bw">
                                                                            {
                                                                                companyNameCorrect
                                                                            }
                                                                        </b>
                                                                    </Tooltip>
                                                                </Col>
                                                                {CompanyName && (<Col className="fx-1">
                                                                    <b className="wb-bw">
                                                                        {
                                                                            CompanyName
                                                                        }
                                                                    </b>
                                                                </Col>)}
                                                            </Row>
                                                        </Col>
                                                        <Col className="fx-1">
                                                            <Row
                                                                type="flex"
                                                                align="middle"
                                                                gutter={10}
                                                            >
                                                                <Col className="fx-1">
                                                                    {populateMidTextCompactHeader()}
                                                                </Col>
                                                                {!isExpanded && (
                                                                    <Col
                                                                        className="pa-0"
                                                                        style={{
                                                                            minWidth: 54,
                                                                            textAlign: 'right'
                                                                        }}
                                                                    >
                                                                        {
                                                                            commentsSection
                                                                        }
                                                                    </Col>
                                                                )}
                                                                {!isExpanded && (
                                                                    <Col
                                                                        className="pa-0"
                                                                        style={{
                                                                            minWidth: 54,
                                                                            textAlign: 'left'
                                                                        }}
                                                                    >
                                                                        {
                                                                            populateRemittanceAdviceCount()
                                                                        }
                                                                    </Col>
                                                                )}
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col
                                                    className="ta-right dates-container"
                                                    style={{
                                                        width: 220,
                                                    }}
                                                >
                                                    <Row
                                                        className="h-100"
                                                        type="flex"
                                                        justify="end"
                                                        align="middle"
                                                        gutter={20}
                                                    >
                                                        <Col
                                                            style={{
                                                                minWidth: 220,
                                                            }}
                                                        >
                                                            {readySection}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col
                                className="ta-right pr-3"
                                style={{ width: 46 }}
                            >
                                {!isExpanded && populateAvatar(32, true)}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            );
        };

        const populateCompactExpandedContent = () => {
            /**
             * Function for populating the Workflow name in certain conditions.
             */
            const populateWorkflowName = () => {
                let workflowNameDisplay = '';
                if (!isNewInvoice && (isCommunication || isCollection || isPhoneCall)) {
                    workflowNameDisplay = get(Workflow, 'WorkflowName');
                }
                
                return (
                    workflowNameDisplay && (
                        <div className="fx-1 ta-right">
                            <b>{getTranslatedText(workflowNameDisplay)}</b>
                        </div>
                    )
                );
            };

            return (
                <Row className="expanded-row" style={{ zIndex: 0 }}>
                    <Col span={24}>
                        <Row>
                            <Col
                                xxl={2}
                                xl={2}
                                lg={2}
                                md={2}
                                sm={2}
                                xs={2}
                                className="pa-0 h-100"
                            >
                                <Row {...mainRowProps}>
                                    <Col
                                        xl={24}
                                        lg={24}
                                        md={24}
                                        sm={24}
                                        xs={24}
                                        className="ta-left"
                                        style={{
                                            ...iconContainerStyle,
                                        }}
                                    >
                                        <div
                                            className="coupled-icon-cont ta-center center-flex-all"
                                            style={{
                                                minHeight: 44,
                                                maxWidth: 66,
                                            }}
                                        >
                                            {generateItemIcon()}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col
                                xxl={22}
                                xl={22}
                                lg={22}
                                md={22}
                                sm={22}
                                xs={22}
                            >
                                <Row gutter={10} type="flex">
                                    <Col className="fx-1 pr-0">
                                        <Row
                                            className="first-row-container"
                                            type="flex"
                                        >
                                            <Col span={24}>
                                                <Row
                                                    className="fw-wr"
                                                    type="flex"
                                                    // gutter={itemGutter}
                                                >
                                                    <Col className="fx-1a">
                                                        <div>{description}</div>
                                                    </Col>
                                                    <Col className="pl-0 pr-0 fx-1a">
                                                        <Row type="flex">
                                                            <Col className="ta-right dates-container fx-1">
                                                                <Row
                                                                    type="flex"
                                                                    justify="end"
                                                                >
                                                                    <Col
                                                                        className="icons-container"
                                                                        style={{
                                                                            lineHeight:
                                                                                '1em',
                                                                        }}
                                                                    >
                                                                        <Row
                                                                            type="flex"
                                                                            align="middle"
                                                                            justify="end"
                                                                        >
                                                                            <Col>
                                                                                {populateWorkflowName()}
                                                                            </Col>
                                                                            <Col
                                                                                className="contact-icons-container ta-right pl-2"
                                                                                style={{
                                                                                    width: 105,
                                                                                }}
                                                                            >
                                                                                {isCommunication &&
                                                                                    contactIcons}
                                                                            </Col>
                                                                            <div
                                                                                style={{
                                                                                    width: 82,
                                                                                    textAlign:
                                                                                        'right',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    commentsSection
                                                                                }
                                                                            </div>
                                                                            <div
                                                                                style={{
                                                                                    width: 82,
                                                                                    textAlign:
                                                                                        'left',
                                                                                }}
                                                                            >
                                                                                {populateRemittanceAdviceCount()}
                                                                            </div>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row
                                            className="fw-wr"
                                            type="flex"
                                            justify="space-between"
                                            gutter={10}
                                        >
                                            <Col
                                                className={
                                                    isLg ? 'fx-1a' : 'fx-1'
                                                }
                                            >
                                                {customFieldsSection}
                                            </Col>
                                            <Col
                                                className={`${
                                                    isLg ? 'fx-1a' : ''
                                                } ta-right`}
                                            >
                                                {assignedToUserSection}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col
                                        className="ta-right pr-8"
                                        style={{ width: 60 }}
                                    >
                                        {populateAvatar()}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            );
        };

        return (
            <Row className="h-100 ma-0">
                <Col span={24}>
                    <Collapse
                        className="compact-collapse"
                        bordered={false}
                        activeKey={isExpanded ? Id : undefined}
                        destroyInactivePanel
                    >
                        <Collapse.Panel
                            disabled
                            header={populateCompactViewListItem()}
                            key={Id}
                        >
                            {isExpanded && populateCompactExpandedContent()}
                        </Collapse.Panel>
                    </Collapse>
                </Col>
            </Row>
        );
    };

    return (
        <Row
            className={`list-item-clickable ${
                selectedId === Id ? 'list-item-clicked' : ''
            }`}
            key={Id}
            style={style}
            onClick={handleRowClick}
        >
            <Col
                span={24}
                style={{
                    height: '100%',
                    paddingTop: 7,
                    paddingBottom: 7,
                }}
                className="container-fw-i"
            >
                {generateRowItemContent()}
            </Col>
        </Row>
    );
};

// export default withAutomationIconsHandler(
//     withDateFormatHandler(withNumberFormatHandler(TaskItemComponent))
// );

const arePropsEqual = (prevProps: any, nextProps: any) => {
    if (
        JSON.stringify(prevProps.item) === JSON.stringify(nextProps.item) &&
        prevProps.selectedId === nextProps.selectedId &&
        isEqual(prevProps.selectedRowKeys, nextProps.selectedRowKeys) &&
        JSON.stringify(prevProps.style) === JSON.stringify(nextProps.style) &&
        prevProps.isExpanded === nextProps.isExpanded &&
        prevProps.addCommentDrawerVisible === nextProps.addCommentDrawerVisible &&
        prevProps.isAppliedRecordLimit === nextProps.isAppliedRecordLimit
    ) {
        return true;
    } else {
        return false;
    }
};

export default memo(
    withAutomationIconsHandler(
        withDateFormatHandler(withNumberFormatHandler(TaskItemComponent))
    ),
    arePropsEqual
);
